import { Avatar, Grid } from "@mui/material";
import React, { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL1 } from "../services/url";
import { useAlert } from "react-alert";

const EditPassword = (porps) => {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [setBtn_Status] = useState("unclick");
  const [allfieldError, setAllfieldError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (oldPass !== "" && newPass !== "" && confirmPass !== "") {
      setAllfieldError(false);
      setBtn_Status("click");
      const data = {
        oldpassword: oldPass,
        password: newPass,
      };

      if (newPass !== confirmPass) {
        alert.error("Passwords don't match");
      } else {
        try {
          const res = await axios.post(`${API_URL1}trucker/resetPass`, data, {
            headers: {
              "x-access-token": `${localStorage.getItem("TruckertokenKey")}`,
              "content-type": "application/json",
            },
          });

          if (res.data.success) {
            alert.success(res.data.message);
            localStorage.removeItem("TruckertokenKey");
            window.location = "/";
          } else {
            alert.error(res.data.message);
          }
        } catch (err) {
          alert.error("something went wrong");
        }
      }
    } else {
      setAllfieldError(true);
    }
  };

  const alert = useAlert();
  return (
    <>
      <Typography
        fontWeight="bold"
        variant="h6"
        color="initial"
        paddingBottom="1%"
        marginLeft="18%"
        marginTop="1%"
        style={{ textAlign: "center" }}
      >
        Reset Password
      </Typography>
      <TableContainer
        component={Paper}
        style={{
          marginLeft: "12%",
        }}
        sx={{
          width: "95%",
          height: "80vh",
          background: "#F3F3F3",
          boxShadow: "5px 5px 6px #434344",
          borderRadius: "10px",
        }}
        align="center"
      >
        <Avatar
          sx={{ height: "100px", width: "100px", mb: "3%", mt: "3%" }}
        ></Avatar>
        <Grid
          container
          spacing={3}
          sx={{
            padding: "0 5% 0 5%",
          }}
        >
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            <Typography
              sx={{ mb: 1 }}
              align="left"
              fontSize="14px"
              fontWeight="bold"
            >
              Old Password
            </Typography>
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
                border: "none",
              }}
              size="small"
              border="none"
              required
              type="password"
              value={oldPass}
              onChange={(e) => setOldPass(e.target.value)}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            {" "}
            <Typography
              sx={{ mb: 1 }}
              fontSize="14px"
              fontWeight="bold"
              align="left"
            >
              New Password
            </Typography>
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
              }}
              type="password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              required
              fullWidth
              size="small"
              border="none"
            ></TextField>
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            {" "}
            <Typography
              sx={{ mb: 1 }}
              fontSize="14px"
              fontWeight="bold"
              align="left"
            >
              Confirm Password
            </Typography>
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
              }}
              type="password"
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
              required
              fullWidth
              size="small"
              border="none"
            ></TextField>
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={3}></Grid>{" "}
          <Grid item xs={8} sm={6}>
            <Button
              width="145px?"
              type="submit"
              variant="contained"
              sx={{
                mt: 3.5,
                textTransform: "none",
                background: "#000",
                borderRadius: "20px",
              }}
              style={{ marginLeft: "2px", width: "15rem" }}
              onClick={handleSubmit}
            >
              Save
            </Button>{" "}
          </Grid>{" "}
          <Grid item xs={8} sm={3}></Grid>
          {allfieldError ? (
            <p style={{ color: "red" }}>All Fields Required</p>
          ) : null}
        </Grid>
        <form></form>
      </TableContainer>
    </>
  );
};

export default EditPassword;
