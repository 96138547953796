import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import swDev from './swDev';

// optional configuration
const options = {
  timeout: 3000,
  position: positions.TOP_CENTER,
  transition: transitions.FADE,
  offset: "100px",
  containerStyle: {
    zIndex: 100,
  },
};

const Root = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>
);
ReactDOM.render(<Root />, document.getElementById("root"));
swDev();
