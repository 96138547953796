import React from "react";
import Button from "react-bootstrap/Button";
import { Link, NavLink } from "react-router-dom";
const DispatchButtons = () => {
  return (
    <div>
      <NavLink to="/dispatcher">
        <Button
          style={{
            float: "left",
            marginTop: "3%",
            marginLeft: "4rem",
            width: "132px",
            color: "white",
            backgroundColor: "#000",
            borderRadius: "10px",
          }}
        >
          {" "}
          Open Jobs
        </Button>
      </NavLink>

      <Link to="/dispatched_order">
        <Button
          style={{
            float: "left",
            marginTop: "3%",
            marginLeft: "2rem",
            width: "162px",
            color: "white",
            backgroundColor: "#000",
            borderRadius: "10px",
          }}
        >
          Dispatched Loads
        </Button>
      </Link>

      <Link to="/dilevery_order">
        <Button
          style={{
            float: "left",
            marginTop: "3%",
            marginLeft: "2rem",
            marginRight: "2rem",
            width: "160px",
            color: "white",
            backgroundColor: "#114C65",
            borderRadius: "10px",
          }}
        >
          Delivered Loads
        </Button>
      </Link>
    </div>
  );
};

export default DispatchButtons;
