import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

const NEW_COORDS = "newCoords";
const SOCKET_SERVER_URL = "http://localhost:4000";

const useMap = (roomId) => {
  const [messages, setMessages] = useState([]);
  const socketRef = useRef();

  // useEffect(() => {
  //   socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
  //     query: { roomId },
  //   });

  //   socketRef.current.on(NEW_COORDS, (message) => {
  //     const incomingMessage = {
  //       ...message,
  //       ownedByCurrentUser: message.senderId === socketRef.current.id,
  //     };
  //     setMessages((messages) => [...messages, incomingMessage]);
  //   });

  //   return () => {
  //     socketRef.current.disconnect();
  //   };
  // }, [roomId]);

  // const sendMessage = (messageBody) => {
  //   socketRef.current.emit(NEW_COORDS, {
  //     body: messageBody,
  //     senderId: socketRef.current.id,
  //   });
  // };

  // return { messages, sendMessage };
};

export default useMap;
