import React from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

class CurrentLocation extends React.Component {
  constructor() {
    super();
    this.state = {
      myLat: 0,
      myLng: 0,
    };
  }

  componentDidMount = async () => {
    navigator.geolocation.getCurrentPosition(
      (res) => {
        this.setState({
          myLat: res.coords.latitude,
          myLng: res.coords.longitude,
        });
      },
      (err) => {
        alert("Fetching the Position failed, please check location is enable!");
      },
      { enableHighAccuracy: true, timeout: 10000 }
    );
  };

  render() {
    return (
      <div style={{ height: "80vh", width: "100%", marginLeft: "20px" }}>
        <div className="col-lg-12" style={{ height: "80vh", width: "90%" }}>
          <Map google={this.props.google} zoom={2} />
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCQKXJbHULUY96hhRoy8jHyrKW_DxuRZdU",
})(CurrentLocation);
