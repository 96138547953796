import React, { useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API_URL1 } from "../services/url";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import "./ForgetPass.css";
import logo from "../images/logo.png";
import { useAlert } from "react-alert";
const UpdatePass = ({ location }) => {
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [confirmPass, setConfirmPass] = useState("");
  const [email, setEmail] = useState("");
  const alert = useAlert();
  // const history = useHistory();

  localStorage.clear();
  const data = {
    email: email,
    otp: otp,
    password: confirmPass,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPass) {
      alert.error("Passwords don't match");
      return;
    } else {
      try {
        const res = await axios.post(
          `${API_URL1}trucker/password/updatePass`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        if (res.data.success) {
          alert.success(res.data.message, {
            onClose: () => {
              localStorage.clear();
              window.location.reload();
              window.location = "/";
            },
          });
        } else if (res.data.message === "incorrect OTP") {
          alert.error("Please enter correct OTP");
        }
      } catch (err) {
        alert.error(err);
      }
    }
  };

  const resendOTP = async (e) => {
    localStorage.setItem("Temail", email);
    e.preventDefault();
    if (email) {
      try {
        const res = await axios.post(`${API_URL1}trucker/password/forgetPass`, {
          email: email,
        });
        if (res.data.success) {
          alert.success(res.data.message, {
            onClose: () => {
              // history.push("/updatepass", { email });
            },
          });
        }
      } catch (err) {
        console.log(err);
        alert.error("somthing went wrong");
      }
    } else {
      alert.error("please enter email", {
        onClose: () => {
          localStorage.clear();
        },
      });
    }
  };
  return (
    <div className="main_body">
      <Navbar style={{ backgroundColor: "black" }}>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={logo}
              style={{ height: "7vh", width: "200px" }}
              className="d-inline-block align-top"
              alt="rockprosusa"
            />
          </Link>
        </Navbar.Brand>
      </Navbar>
      <div className="container  outer_div pt-5">
        <h1 className="text-center">Reset Password</h1>
        <Form onSubmit={handleSubmit} className="">
          <Form.Group className="mb-3 ">
            <Form.Label style={{ fontSize: "20px", paddingBottom: "0.5rem" }}>
              Enter Registered Email
            </Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
          </Form.Group>
          <Form.Group className="mb-3 ">
            <Form.Label style={{ fontSize: "20px", paddingBottom: "0.5rem" }}>
              Enter OTP
            </Form.Label>
            <Form.Control
              className="otp-field"
              type="text"
              name="otp"
              onChange={(e) => setOtp(e.target.value)}
              maxLength="4"
            ></Form.Control>
            <Link
              className="float-right"
              to="/updatepass"
              onClick={(e) => resendOTP(e)}
            >
              Resend OTP
            </Link>
          </Form.Group>
          <Form.Group className="mb-3 ">
            <Form.Label style={{ fontSize: "20px", padding: "0.5rem" }}>
              New Password
            </Form.Label>
            <Form.Control
              autoComplete="off"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
          </Form.Group>
          <Form.Group className="mb-3 ">
            <Form.Label style={{ fontSize: "20px", padding: "0.5rem" }}>
              Confirm Password
            </Form.Label>
            <Form.Control
              type="password"
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
              required={true}
            />
          </Form.Group>
          <button className="submit-btn" type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
};

export default UpdatePass;
