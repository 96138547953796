import React, { useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import useMap from "./useMap";
import socketIOClient from "socket.io-client";

const NEW_COORDS = "newCoords";
const SOCKET_SERVER_URL = "http://localhost:4000";

const ProsMap = (props) => {
  const socketRef = useRef();

  const { messages, sendMessage } = useMap(props.roomId);
  const roomId = props.roomId;
  const [center, setCenter] = useState({ lat: 32.74580332356024, lng: -111.6859092361447});
  const [zoom, setZoom] = useState(10);
  let l;

  const [loc, setLoc] = useState(null);

  // useEffect(() => {
  //   socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
  //     query: { roomId },
  //   });

  //   socketRef.current.on(NEW_COORDS, (message) => {
  //     setLoc(message);
  //     l = message;
  //     setCenter(message.body);
  //   });

  //   return () => {
  //     socketRef.current.disconnect();
  //   };
  // }, [props.roomId]);

  useEffect(() => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos) {
      var crd = pos.coords;

      sendMessage({ lat: crd.latitude, lng: crd.longitude });
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    setInterval(() => {
      navigator.geolocation.getCurrentPosition(success, error, options);
    }, 5000);
  }, []);

  const handleApiLoaded = (map, maps) => {
    setInterval(() => {
      const marker = new maps.Marker({
        position: l?.body,
        map: map,
      });
    }, 5000);

    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();
    directionsRenderer.setMap(map);

    const onChangeHandler = function () {
      calculateAndDisplayRoute(directionsService, directionsRenderer);
    };
    onChangeHandler();

    function calculateAndDisplayRoute(directionsService, directionsRenderer) {
      directionsService.route(
        {
          origin: props.origin,
          destination: props.destination,
          travelMode: maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            directionsRenderer.setDirections(response);
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    }
  };
  return (
    <div
      className="container justify-space-around mt-5 row"
      style={{ width: "100%" }}
    >
      <div style={{ height: "80vh", width: "100%", marginLeft: "20px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCQKXJbHULUY96hhRoy8jHyrKW_DxuRZdU" }}
          defaultCenter={center}
          defaultZoom={zoom}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          yesIWantToUseGoogleMapApiInternals
        ></GoogleMapReact>
      </div>
    </div>
  );
};
export default ProsMap;
