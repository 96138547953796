import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ForgetPass from "./component/forgetPass/ForgetPass";
import UpdatePass from "./component/forgetPass/UpdatePass";
import Signin from "./component/login/Login";
import SignUp from "./component/signup/Signup";
import EditUser from "./component/NavigationBar/EditUser";
import EditPassword from "./component/NavigationBar/ResetPassword";
import ViewDriver from "./component/Driver/ViewDriver";
import EditDriver from "./component/Driver/EditDriver";
import AddDriver from "./component/Driver/AddDriver";
import ViewTruck from "./component/Truck/ViewTruck";
import EditTruck from "./component/Truck/EditTruck";
import AddTruck from "./component/Truck/AddTruck";
import Complain from "./component/Complain/Complain";
import Dispatcher from "./component/dispatcherNew/OpenOrder";
import DispatchedOrder from "./component/dispatcherNew/DispatchedOrder";
import RescheduleOrder from "./component/dispatcherNew/RescheduleOrder";
import DispatchOrder from "./component/dispatcherNew/DispatchOrder";
import DispatchedMap from "./component/dispatcherNew/DispatchedMap";
import DeliveredMap from "./component/dispatcherNew/DeliveredMap";
import DispatcherNew from "./component/dispatcherNew/create-order";
import ViewComplain from "./component/Complain/ViewComplain";
import MapViewOrder from "./component/new_map/DisplayMap";
import T_Availability from "./component/T_Availability/T_Availability";

import NavigationBar from "./component/NavigationBar/NavigationBar";
import SideBar from "./component/sidebar/Sidenavbar";
import Dashboard from "./component/DashboardNew/Dashboard";
import DeliveryLoadout from "./component/dispatcherNew/DeliveryLoadout";
import Grid from "@mui/material/Grid";
import "./App.css";
import addNotification from "react-push-notification";
import notifLogo from "./component/images/logo512.png";
// import io from "socket.io-client";
// import { SOCKET_URL } from "./component/services/url";
// var socket = io(SOCKET_URL);
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      route: "signin",
      isSignedIn: false,
      trucker_status: "",
      notifications: [],
      notifCount: [],
    };
  }

  setNotifications = (notif) => {
    notif.clicked = false;
    this.setState((prevState) => ({
      notifications: [...prevState.notifications, notif],
      notifCount: [...prevState.notifCount, notif],
    }));
  };

  clearNotifCount = () => {
    this.setState({ notifCount: [] });
  };

  markAllAsRead = () => {
    this.setState((prevState) => ({
      notifications: prevState.notifications.map((notification) => ({
        ...notification,
        clicked: true,
      })),
    }));
  };

  handleNotificationClick = (clickedNotification) => {
    this.setState((prevState) => ({
      notifications: prevState.notifications.map((notification) =>
        notification === clickedNotification
          ? { ...notification, clicked: true }
          : notification
      ),
    }));
    clickedNotification.onClick();
  };

  onRouteChange = (route) => {
    if (route === "signout") {
      this.setState({ isSignedIn: false });
    } else if (route === "dashboard") {
      this.setState({ isSignedIn: true });
    }
    this.setState({ route: route });
  };

  async componentDidMount() {
    console.log("###", localStorage.getItem("trucker_id"));
    if (localStorage.getItem("adminid")) {
      // socket.emit("add-user", localStorage.getItem("adminid"));
    }

    if (
      localStorage.getItem("TruckertokenKey") &&
      localStorage.getItem("TruckertokenKey") !== "undefined"
    ) {
      const base64Url = localStorage.getItem("TruckertokenKey").split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      var tokenData = JSON.parse(window.atob(base64));

      this.setState({ trucker_status: tokenData.trucker_status });
    }

    // socket.on("status-recieve", (status) => {
    //   if (status === 1) {
    //     localStorage.clear();
    //     window.location = "/";
    //   }
    // });

    // socket.on("receive-order-dispatch-schedule-notification", (payload) => {
    //   const orderId = payload.po_number;
    //   const notif = {
    //     title: "Order Dispatch Request",
    //     message: `You received a dispatch request for the order having id #${orderId}.`,
    //     onClick: () =>
    //       window.location.replace("http://localhost:8080/dispatcher"),
    //     clicked: false,
    //   };

    //   addNotification({
    //     title: notif.title,
    //     message: notif.message,
    //     theme: "darkblue",
    //     icon: notifLogo,
    //     duration: 5000,
    //     onClick: notif.onClick,
    //     native: true,
    //   });

    //   this.setNotifications(notif);
    // });

    // socket.on("receive-loadout-notification", (payload) => {
    //   const notif = {
    //     title: "Order Dispatch Request!",
    //     message: `You received a dispatch request for the #{id}.`,
    //     onClick: () =>
    //       window.location.replace("http://localhost:8080/dispatched_order"),
    //     clicked: false,
    //   };

    //   addNotification({
    //     title: notif.title,
    //     message: notif.message,
    //     theme: "darkblue",
    //     icon: notifLogo,
    //     duration: 5000,
    //     onClick: notif.onClick,
    //     native: true,
    //   });

    //   this.setNotifications(notif);
    // });

    // socket.on("receive-comment-resolution-notification", (payload) => {
    //   console.log("###", payload);
    //   const notif = {
    //     title: "Comment Resolution",
    //     message: `You received a resolution for your complaint having id #${payload.complainId}.`,
    //     onClick: () =>
    //       window.location.replace("http://localhost:8080/dispatched_order"),
    //     clicked: false,
    //   };

    //   addNotification({
    //     title: notif.title,
    //     message: notif.message,
    //     theme: "darkblue",
    //     icon: notifLogo,
    //     duration: 5000,
    //     onClick: notif.onClick,
    //     native: true,
    //   });

    //   this.setNotifications(notif);
    // });
  }

  render() {
    const { trucker_status, notifications, notifCount } = this.state;

    return (
      <Router>
        <Routes>
          <Route path="/register" element={<SignUp />} />
          <Route path="/forgetpass" element={<ForgetPass />} />
          <Route path="/updatepass" element={<UpdatePass />} />
          </Routes>
          {trucker_status === 0 && localStorage.getItem("TruckertokenKey") ? (
            <React.Fragment>
              <NavigationBar
                notifications={notifications}
                notifCount={notifCount}
                clearNotifCount={this.clearNotifCount}
                markAllAsRead={this.markAllAsRead}
                handleNotificationClick={this.handleNotificationClick}
              />
              <Grid container  style={{marginTop:'80px' }}>
                <Grid xs={2} sm={2} item>
                  <div>
                    <SideBar style={{ position: "sticky" }} />
                  </div>
                </Grid>
                <Grid xs={10} sm={10}>
                  <div className="col-10 m-0 p-0 " style={{ height: "60vh" }}>
                  <Routes>
                    <Route
                      path="/editdriver/:driver_id/:truck_id"
                      element={<EditDriver />}
                    />
                    <Route path="/edituser" element={<EditUser />} />
                    <Route path="/resetpass" element={<EditPassword />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/viewdriver" element={<ViewDriver />} />
                    <Route path="/map" element={<MapViewOrder />} />
                    <Route path="/viewtruck" element={<ViewTruck />} />
                    <Route path="/addtruck" element={<AddTruck />} />
                    <Route path="/edittruck" element={<EditTruck />} />
                    <Route path="/adddriver" element={<AddDriver />} />
                    <Route path="/complain" element={<Complain />} />
                    <Route path="/Viewcomplain" element={<ViewComplain />} />
                    <Route path="/dispatcher" element={<Dispatcher />} />
                    <Route
                      path="/dispatched_order"
                      element={<DispatchedOrder />}
                    />
                    <Route
                      path="/dilevery_order"
                      element={<DeliveryLoadout />}
                    />
                    <Route
                      path="/dispatched_order/:id"
                      element={<DispatchOrder />}
                    />
                    <Route
                      path="/reschedule_order/:id"
                      element={<RescheduleOrder />}
                    />
                    <Route
                      path="/dispatchorder/:id"
                      element={<DispatcherNew />}
                    />
                    <Route
                      path="/truck_Availability"
                      element={<T_Availability />}
                    />
                    <Route
                      path="/dispatched_map/:sub_job_truck_id/"
                      element={<DispatchedMap />}
                    />
                    <Route
                      path="/deliveredMap/:sub_job_truck_id/"
                      element={<DeliveredMap />}
                    />
                      </Routes>
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <Routes>
            <Route path="/" element={<Signin />} />
            </Routes>
          )}
      </Router>
    );
  }
}

export default App;
