import { Box } from "@mui/material";
import React, { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { API_URL1 } from "../services/url";
import { useAlert } from "react-alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import io from "socket.io-client";
// import { SOCKET_URL } from "../services/url";
// var socket = io(SOCKET_URL);

const EditDriver = () => {
  const [description, setDescription] = useState("");
  const [commenttype, seCommnetType] = useState("");
  const [btn_status, setBtn_Status] = React.useState("");
  const [error, setfeildeErr] = useState(false);
  const alert = useAlert();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user = {
      complain_type: commenttype,
      description: description,
      truckerAdminId: localStorage.getItem("adminid"),
    };
    if (description !== "" && commenttype !== "") {
      try {
        const response = await axios.post(`${API_URL1}trucker/complain`, user, {
          headers: {
            "x-access-token": `${localStorage.getItem("TruckertokenKey")}`,
            "content-type": "application/json",
          },
        });
        if (response.data.success) {
          setBtn_Status("clicked");
          const truckerName = localStorage.getItem("company_name");
          // socket.emit("send-comment-byTrucker-notification", {
          //   user,
          //   truckerName,
          // });
          alert.success("Comments Added", {
            onClose: () => {
              window.location = "/viewcomplain";
            },
          });
        } else {
          alert.error(response.data.message, {
            onClose: () => {
              window.location = "/viewcomplain";
            },
          });
        }
      } catch (error) {
        alert.error("something went wrong", {
          onClose: () => {
            window.location = "/viewcomplain";
            setBtn_Status("");
          },
        });
      }
    } else {
      setfeildeErr(true);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          marginLeft: "8%",
          marginTop: "8%",
          left: "50%",
          height: "70vh",
          background: "#F3F3F3",
          boxShadow: "5px 5px 6px #434344",
          borderRadius: "10px",
        }}
        align="center"
      >
        <Paper
          style={{
            marginTop: "7%",
            background: "#554D4D",
            borderRadius: "30px",
            height: "60%",
            width: "45%",
            color: "#fff",
          }}
        >
          <Typography sx={{ padding: "10px" }} variant="body1" color="#fff">
            Add a comment
          </Typography>
          {error && description == "" && commenttype == "" ? (
            <p style={{ color: "red" }}>* All Fields Required</p>
          ) : null}
          {error && description == "" ? (
            <p style={{ color: "red" }}>* Description field required </p>
          ) : null}
          {error && commenttype == "" ? (
            <p style={{ color: "red" }}>* Commen type field required</p>
          ) : null}

          <form onSubmit={handleSubmit}>
            <Paper sx={{ height: "80%" }}>
              <Box sx={{ width: "80%" }}>
                <Typography
                  variant="body1"
                  color="initial"
                  align="left"
                  sx={{ paddingTop: "5px" }}
                >
                  Comment
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={commenttype}
                    label="Comment Type"
                    required
                    onChange={(e) => seCommnetType(e.target.value)}
                    sx={{
                      background: "#F0F0F0",
                      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  >
                    <MenuItem disabled>Select Comment Type</MenuItem>
                    <MenuItem value={"Trucker"}>Trucker</MenuItem>
                    <MenuItem value={"Material"}>Material</MenuItem>
                    <MenuItem value={"Price"}>Price</MenuItem>
                    <MenuItem value={"Time"}>Time</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  variant="body1"
                  color="initial"
                  align="left"
                  sx={{ paddingTop: "10px" }}
                >
                  Description
                </Typography>
                <TextField
                  id=""
                  label=""
                  size="large"
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={{
                    height: "30%",
                  }}
                  sx={{
                    background: "#F0F0F0",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                />
                <Button
                  style={{
                    width: "30%",
                    color: "white",
                    background: "#000",
                    borderRadius: "20px",
                    marginRight: "16px",
                    marginBottom: "8px",
                    marginTop: "10px",
                    textTransform: "none",
                  }}
                  variant="text"
                  color="primary"
                  type="submit"
                  disabled={btn_status === "clicked"}
                  onClick={(e) => handleSubmit(e)}
                >
                  Submit
                </Button>
              </Box>
            </Paper>
          </form>
        </Paper>
      </TableContainer>
    </>
  );
};

export default EditDriver;
