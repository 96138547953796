import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import "./BlockData.css";

export default function BlockData(props) {
  return (
    <Fragment>
      {props.loading ? (
        <div className="row mt-5 text-center align-content-center d-flex justify-content-center ">
          <div
            className="spinner-grow text-gray"
            style={{ height: "100px", width: "100px" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row col-lg-12 justify-content-around">
            <Grid item xs={3}>
              <Paper className="divcss" style={{ backgroundColor: "#E4E4E4" }}>
                <div className="top1">
                  <div className="p1">Trucks Dispatched Today:</div>
                  <div className="p2">
                    {props.dispatch_today
                      ? parseFloat(props.dispatch_today).toFixed(2)
                      : 0}
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper className="divcss" style={{ backgroundColor: "#E4E4E4" }}>
                <div className="top1">
                  <div className="p1">Tons Moved Today:</div>
                  <div className="p2">
                    {props.tons_today
                      ? parseFloat(props.tons_today).toFixed(2)
                      : 0}
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper className="divcss" style={{ backgroundColor: "#E4E4E4" }}>
                <div className="p1">Loads Hauled Today:</div>
                <div className="p2">
                  {props.hauled_today
                    ? parseFloat(props.hauled_today).toFixed(2)
                    : 0}
                </div>
              </Paper>
            </Grid>
          </div>
          <div className="row col-lg-12 justify-content-around mt-5">
            <Grid item xs={3}>
              <Paper className="divcss" style={{ backgroundColor: "#E4E4E4" }}>
                <div className="top1">
                  <div className="p1">Trucks Dispatched This Month:</div>
                  <div className="p2">
                    {props.dispatch_week
                      ? parseFloat(props.dispatch_week).toFixed(2)
                      : 0}
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper className="divcss" style={{ backgroundColor: "#E4E4E4" }}>
                <div className="top1">
                  <div className="p1">Tons Moved This Month:</div>
                  <div className="p2">
                    {props.tons_week
                      ? parseFloat(props.tons_week).toFixed(2)
                      : 0}
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper className="divcss" style={{ backgroundColor: "#E4E4E4" }}>
                <div className="top1">
                  <div className="p1">Loads Hauled This Month:</div>
                  <div className="p2">
                    {props.hauled_week
                      ? parseFloat(props.hauled_week).toFixed(2)
                      : 0}
                  </div>
                </div>
              </Paper>
            </Grid>
          </div>

          <div className="row col-lg-12 justify-content-around mt-5">
            <Grid item xs={3}>
              <Paper className="divcss" style={{ backgroundColor: "#E4E4E4" }}>
                <div className="top1">
                  <div className="p1">Trucks Dispatched This Year:</div>
                  <div className="p2">
                    {props.dispatch_year
                      ? parseFloat(props.dispatch_year).toFixed(2)
                      : 0}
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper className="divcss" style={{ backgroundColor: "#E4E4E4" }}>
                <div className="top1">
                  <div className="p1">Tons Moved This Year:</div>
                  <div className="p2">
                    {props.tons_year
                      ? parseFloat(props.tons_year).toFixed(2)
                      : 0}
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper className="divcss" style={{ backgroundColor: "#E4E4E4" }}>
                <div className="top1">
                  <div className="p1">Loads Hauled This Year:</div>
                  <div className="p2">
                    {props.hauled_year
                      ? parseFloat(props.hauled_year).toFixed(2)
                      : 0}
                  </div>
                </div>
              </Paper>
            </Grid>
          </div>
        </>
      )}
    </Fragment>
  );
}
