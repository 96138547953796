import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Logout from "./LogoutModal";
import { Button, Dropdown, NavDropdown } from "react-bootstrap";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import * as React from "react";
import { Fragment } from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import NotificationBadge from "react-notification-badge/lib/components/NotificationBadge";
import { Effect } from "react-notification-badge";
import "./NavigationBar.css";

const NavigationBar = ({
  notifications,
  notifCount,
  clearNotifCount,
  markAllAsRead,
  handleNotificationClick,
}) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const notificationsReverse = [...notifications].reverse();
  const truckerUserName = localStorage.getItem("company_name");

  return (
    <Fragment>
      <AppBar
        position="absolute"
        style={{ backgroundColor: "#434344", width: "100%" ,height:'90px'}}
      >
        {/* <Container
          // maxWidth="lg"
          style={{ backgroundColor: "#303230", width: "100%" }}
        > */}
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              component="div"
              sx={{  display: { xs: "flex", marginRight: "3%" } }}
            >
              <Link to="/">
                <img
                  style={{ height: "4rem", width: "17rem", marginLeft: "10%"}}
                  src={logo}
                  alt="LOGO"
                />
              </Link>
            </Typography>
            <NavDropdown
              onClick={clearNotifCount}
              className="mr-2 first-dropdown"
              style={{marginLeft:'75%'}}
              title={
                <>
                  <NotificationBadge
                    style={{ zIndex: "10000" }}
                    count={notifCount.length}
                    effect={Effect.SCALE}
                  />

                  <ion-icon
                    name="notifications"
                    style={{
                      color: "#fff",
                      fontSize: "22px",
                    }}
                  ></ion-icon>
                </>
              }
              id="collasible-nav"
            >
              <div className="p-3" >
                <div className="d-flex align-items-center justify-content-between">
                  <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Notifications
                  </p>
                  <Button
                    title="Mark all as read"
                    className="mark-all-as-read-btn"
                    onClick={markAllAsRead}
                  >
                    <ion-icon
                      style={{ fontSize: "30px" }}
                      name="checkmark-done-circle"
                    ></ion-icon>
                  </Button>
                </div>

                {notifications.length > 0 ? (
                  <div>
                    {notificationsReverse.map((notification, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: notification.clicked
                            ? "transparent"
                            : "#dee2e6",
                        }}
                        onClick={() => handleNotificationClick(notification)}
                      >
                        <NavDropdown.Item
                          style={{ fontSize: "16px" }}
                          onClick={notification.onClick}
                        >
                          <p
                            className="m-0"
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              color: notification.clicked ? "#6c757d" : "#000",
                            }}
                          >
                            {notification.title}
                          </p>
                          <p
                            style={{
                              whiteSpace: "pre-wrap",
                              fontSize: "14px",
                              color: notification.clicked ? "#6c757d" : "#000",
                            }}
                            className="m-0"
                          >
                            {notification.message}
                          </p>
                        </NavDropdown.Item>
                        {index !== notifications.length - 1 && (
                          <Dropdown.Divider />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="p-2">
                    <NavDropdown.Item style={{ fontSize: "16px" }}>
                      No new Notifications
                    </NavDropdown.Item>
                  </div>
                )}
              </div>
            </NavDropdown>

            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <NavDropdown
                  title={
                    <span className="text-white">
                      {localStorage.getItem("company_name")
                        ? localStorage.getItem("company_name")
                        : ""}
                      <span> </span>
                    </span>
                  }
                >
                  <Link to="/edituser">
                    <button
                      style={{
                        backgroundColor: "#2A707D",
                        width: "10rem",
                        marginLeft: "17px",
                      }}
                    >
                      Edit User
                    </button>
                  </Link>
                  <NavDropdown.Item></NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to="/resetpass">
                      <button
                        style={{ backgroundColor: "#2A707D", width: "10rem" }}
                      >
                        Reset Password
                      </button>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </IconButton>
              <Logout />
            </Box>
          </Toolbar>
      </AppBar>
    </Fragment>
  );
};
export default NavigationBar;
