import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { API_URL1 } from "../services/url";
import { withAlert } from "react-alert";
import { FcDownload } from "react-icons/fc";

class TruckerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      Pdfname: "",
      btn_status: "unclick",
      loading: false,
    };
  }
  handleShow = async (evt) => {
    /**load pdf api call */
    this.setState({ loading: true });
 
    try {
      const response2 = await axios.get(
        `${API_URL1}appAdmin/dispatchManagement/dispatch/get_trucker_pdf?truck_request_id=${this.props.truck_request_id}`,
        {
          responseType: "blob",
        }
      );
      var fileURL = URL.createObjectURL(response2.data);
      this.setState({ show: true });
      document.querySelector("iframe").src = fileURL;
      this.setState({ Pdfname: fileURL });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };
  handleClose = () => {
    this.setState({ show: false });
    // window.location.reload();
  };
  render() {
    return (
      <React.Fragment>
        <div>
          {this.props.truck_request_id ? (
            <FcDownload
              style={{
                color: "green",
                fontSize: "15px",
                marginLeft: "6px",
                cursor: "pointer",
              }}
              onClick={this.handleShow}

            />
          ) : (
            <FcDownload
              style={{
                color: "grey",
                fontSize: "15px",
                marginLeft: "6px",
              }}
            />
          )}
        </div>

        <Modal show={this.state.show} onHide={this.handleClose} size="lg">
          <div
            style={{
              backgroundColor: "#fff",
              left: 100,
              position: "fixed",
              marginTop: 30,
              right: 100,

              height: "90%",
              width: "80%",
            }}
          >
            <iframe
              style={{ width: "100%", height: "100%" }}
              src={this.state.Pdfname}
              type="application/pdf"
            ></iframe>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withAlert()(TruckerForm);
