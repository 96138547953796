import React from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { API_URL1 } from "../services/url";
import { withAlert } from "react-alert";
import FileDownloadIcon from "@mui/icons-material/GetApp";

class DownloadQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      Pdfname: "",
      btn_status: "unclick",
      loading: false,
    };
  }
  handleShow = async (evt) => {
    /**load pdf api call */
    this.setState({ loading: true });
    try {
      await axios.get(
        `${API_URL1}aggregate/loadedpdf1?sub_job_id=${this.props.sub_job_truck_id}`,
        {
          responseType: "blob",
        }
      );

      this.setState({ loading: false });
      this.handleShow1();
    } catch (error) {
      this.setState({ loading: false });
      this.handleShow1();
    }
  };
  handleShow1 = async (evt) => {
    /**load pdf api call */
    try {
      const response2 = await axios.get(
        `${API_URL1}aggregate/loadedpdf1?sub_job_id=${this.props.sub_job_truck_id}`,
        {
          responseType: "blob",
        }
      );

      var fileURL = URL.createObjectURL(response2.data);
      this.setState({ show: true });
      document.querySelector("iframe").src = fileURL;
      this.setState({ Pdfname: fileURL });
    } catch (error) {
      console.log(error);
    }
  };
  handleClose = () => {
    this.setState({ show: false });
    window.location.reload()

    // window.location = "/dispatched_order";
  };
  render() {
    return (
      <React.Fragment>
        {this.state.loading ? (
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <FileDownloadIcon
            style={{
              color: "red",
              marginLeft: "7px",
              marginRight: "5px",
              cursor: "pointer",
            }}
            onClick={(evt) => this.handleShow(evt)}
          />
        )}

        <Modal show={this.state.show} onHide={this.handleClose} size="lg">
          <div
            style={{
              backgroundColor: "#fff",
              left: 100,
              position: "fixed",
              top: 10,
              right: 100,
              marginTop:'4%',
              height: "90%",
              width: "80%",
            }}
          >
            <iframe
               
                style={{ width: "100%", height: "100%" ,}}
              src={this.state.Pdfname}
              type="application/pdf"
            ></iframe>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withAlert()(DownloadQuote);
