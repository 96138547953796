import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { API_URL1 } from "../services/url";
import PhoneInput from "react-phone-input-2";
import { useAlert } from "react-alert";

const useStyles = makeStyles({
  root: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },
});

const ResetPass = () => {
  let [name, setName] = useState("");

  let [phone_no, setPhone_no] = useState("");
  let [truck, setTruck] = useState("");
  let [user, setUser] = useState([]);
  const classes = useStyles();

  const alert = useAlert();

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handlephone = (e) => {
    setPhone_no(e);
  };

  const handlePhoneNo = (e) => {
    setPhone_no(e.target.value);
  };
  const handleAdd = async (event) => {
    const truckId = parseInt(truck);
    event.preventDefault();
    let phone=phone_no;
    if (phone.charAt(0) === "9" && phone.charAt(1) === "1") {
      var mob = "+91 ";

      for (let i = 2; i < phone.length; i++) {
        if (
          phone[i] !== " " &&
          phone[i] !== "(" &&
          phone[i] !== ")" &&
          phone[i] !== "-"
        )
          mob = mob + phone[i];
      }
      phone = mob;
    }
    if (phone.charAt(0) === "1") {
      var mob = "+1 ";

      for (let i = 1; i < phone.length; i++) {
        if (
          phone[i] !== " " &&
          phone[i] !== "(" &&
          phone[i] !== ")" &&
          phone[i] !== "-"
        )
          mob = mob + phone[i];
      }

      phone = mob;
    }
    const editData = {
      name: name,

      phone_no: phone,
      truck_company_id: localStorage.getItem("adminid"),
    };
    console.log("phone_no profile",phone_no)
    try {
      const response = await axios.put(
        `${API_URL1}trucker/truck/editTruckCompanyUser`,
        editData,
        {
          headers: {
            "x-access-token": `${localStorage.getItem("TruckertokenKey")}`,
            "content-type": "application/json",
          },
        }
      );
      if (response.data.success) {
        alert.success(response.data.message, {
          onClose: () => {
            window.location = "/";
          },
        });
      } else {
        alert.error("Error in update Profile", {
          onClose: () => {
            window.location = "/editUser";
          },
        });
      }
    } catch (error) {
      alert.error("Error in update Profile", {
        onClose: () => {
          // window.location = '/viewdriver';
        },
      });
    }
  };
  const getUserData = async () => {
    try {
      const res = await axios.get(
        `${API_URL1}trucker/truck/truckCompanyUser/${localStorage.getItem(
          "adminid"
        )}/${localStorage.getItem("trucker_id")}`
      );
      setName(res.data.User[0].name);
      setPhone_no(res.data.User[0].phone_no);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);
  console.log("phone_no",phone_no)
  return (
    <>
      <Typography
        fontWeight="bold"
        variant="h6"
        color="initial"
        marginLeft="20%"
        paddingTop="1%"
        paddingBottom="1%"
        style={{ textAlign: "center" }}
      >
        Edit Profile
      </Typography>
      <TableContainer
        component={Paper}
        style={{
          marginLeft: "13%",
          boxShadow: "5px 5px 6px #434344",
        }}
        sx={{
          width: "95%",
          height: "80vh",
          background: "#F3F3F3",
          boxShadow: "",
          borderRadius: "10px",
        }}
        align="center"
      >
        <Avatar
          sx={{
            mt: "3%",
            mb: "1%",
            width: "100px",
            height: "100px",
          }}
        />
        <form onSubmit={handleAdd}>
          <Grid
            container
            spacing={3}
            sx={{
              padding: "0 5% 0 5%",
            }}
          >
            <Grid item xs={8} sm={4}></Grid>
            <Grid item xs={8} sm={4}>
              <Typography
                sx={{
                  mb: 1,
                }}
                fontSize="14px"
                align="left"
                fontWeight="bold"
                variant="body2"
                color="initial"
              >
                Name
              </Typography>
              <TextField
                sx={{
                  background: "#FFFFFF",
                  boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",

                  border: "none",
                }}
                name="name"
                value={name}
                onChange={handleName}
                size="small"
                className={classes.root}
                autoComplete="given-name"
                border="none"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={8} sm={4}></Grid>
            <Grid item xs={8} sm={4}></Grid>
            <Grid item xs={8} sm={4}></Grid>
            <Grid item xs={8} sm={4}></Grid>
            <Grid item xs={8} sm={4}></Grid>
            <Grid item xs={8} sm={4}>
              <Typography
                sx={{
                  mb: 1,
                }}
                fontSize="14px"
                align="left"
                fontWeight="bold"
                variant="body2"
                color="initial"
              >
                Contact Number
              </Typography>


              <PhoneInput
                required
                country={"us"}
                value={phone_no}
                inputStyle={{ width: "240px" }}
                onChange={(phone_no) => handlephone(phone_no)}
              />
            </Grid>
            <Grid item xs={8} sm={4}></Grid>
            <Grid item xs={8} sm={3}></Grid>
            <Grid item xs={8} sm={6}>
              <Button
                onClick={handleAdd}
                type="submit"
                width="145px?"
                variant="contained"
                sx={{
                  mt: 3.5,
                  textTransform: "none",
                  background: "#000",
                  borderRadius: "20px",
                }}
                style={{ marginLeft: "2px", width: "15rem" }}
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={8} sm={3}></Grid>
          </Grid>
        </form>
      </TableContainer>
    </>
  );
};

export default ResetPass;
