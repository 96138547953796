import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@material-ui/core/Card";
import { API_URL1 } from "../services/url";
import Button from "react-bootstrap/Button";
import Pros from "./Pros";
import CurrentLocation from "./CurrentLocation";

function DisplayMap(props) {
  const [first, setfirst] = useState("No");
  const [second, setsecond] = useState("");
  const [orders, setorders] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${API_URL1}trucker/showMap`, {
          headers: {
            "x-access-token": `${localStorage.getItem("TruckertokenKey")}`,
            "content-type": "application/json",
          },
        });

        setorders(response.data.order);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const roomUpdate = (roomid, origin, destination) => {
    const origin1 = {
      lat: JSON.parse(origin).lat,
      lng: JSON.parse(origin).lng,
    };
    const dest1 = {
      lat: JSON.parse(destination).lat,
      lng: JSON.parse(destination).lng,
    };

    if (first === "No") {
      setfirst(origin1);
      setsecond(dest1);
    } else {
      setfirst("No");
      setTimeout(() => {
        setfirst(origin1);
        setsecond(dest1);
      }, 100);
    }
  };
  return (
    <div
      className="container justify-space-around mt-5 row"
      style={{ width: "100%" }}
    >
      <div className="col-3" style={{ overflow: "scroll", height: "75vh" }}>
        {orders &&
          orders.map((item, index) => {
            return (
              <Card
                key={index}
                style={{
                  backgroundColor: "#f0f0f0",
                  boxShadow: "5px 5px 8px #888888",
                  marginBottom: "3px",
                }}
              >
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Customer Name : {item.name}
                  </h5>
                  <h6
                    className="card-subtitle"
                    style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  >
                    Order Detail :
                  </h6>
                  <p>
                    <strong>Customer Site :</strong>
                    {item.customer_site_name}
                    <br />
                    <strong>Size :</strong>
                    {item.size} <br />
                    <strong>Color :</strong> {item.color_name}
                    <br />
                    <strong>Quantity :</strong>
                    {item.processing_quantity} Tons
                    <br />
                  </p>
                  <div>
                    <Button
                      size="small"
                      style={{ backgroundColor: "#2A707D" }}
                      className="mr-3 mt-2"
                      onClick={() => {
                        roomUpdate(
                          "123456",
                          item.quarry_geofence,
                          item.geofence_data
                        );
                      }}
                    >
                      View Location
                    </Button>
                  </div>
                </div>
              </Card>
            );
          })}
      </div>
      <div
        style={{
          height: "60vh",
          width: "70%",
          marginLeft: "30px",
          marginRight: "20px",
        }}
      >
        {first === "No" ? (
          <CurrentLocation />
        ) : (
          <Pros roomId="mmmnnn" origin={first} destination={second} />
        )}
      </div>
    </div>
  );
}
export default DisplayMap;
