import React from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Box, Grid } from "@mui/material/";
import { API_URL1 } from "../services/url";
import TablePagination from "@material-ui/core/TablePagination";
import { TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import OrderDetail from "./OrderInfo";
import TruckDetail from "./TruckInfo";
import CurrentLocation from "./CurrentLocation";
import { withAlert } from "react-alert";
import moment from "moment";
import DeliveredButtons from "./DeliveredButtons";
import DeliveryViewLoadTicket from "./DeliveryViewLoadTicket";
import AddLocationAltSharpIcon from "@mui/icons-material/AddLocationAltSharp";
import DownloadPdf from "./DownloadPdf";
class Dispatcher extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 0,
      rowsPerPage: 100,
      recentOrder: [],
      record: false,
      hideMAP: false,
      sortedByDeparted: false,
      sordedByPU: false,
      sortedByCrossStreet: false,
      sortedByDelivered: false,
      sortedByJobName: false,
      sortedByPickup: false,
      sortedByQty: false,
      sortedById: false,
      sortedByLot: false,
      sortedByLotAddress: false,
      sortedByName: false,
      sortedByContract: false,
      deliverd: false,
      nonFilteredData: [],
    };
  }
  componentDidMount = async () => {
    try {
      const response = await axios.get(
        `${API_URL1}trucker/dispatch/dispatchdelivered`,
        {
          headers: {
            "x-access-token": localStorage.getItem("TruckertokenKey"),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (response.data.data) {
        this.setState({
          recentOrder: response.data.data,
          nonFilteredData: response.data.data,
          record: true,
        });
      } else {
        this.setState({ recentOrder: [], record: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
    this.setState({ page: 0 });
  };

  sortQty = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByQty) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.processing_quantity < b.processing_quantity) {
          return -1;
        }
        if (a.processing_quantity > b.processing_quantity) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByQty: true });
    }
  };
  sortJobName = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByJobName) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.customer_site_name < b.customer_site_name) {
          return -1;
        }
        if (a.customer_site_name > b.customer_site_name) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByJobName: true });
    }
  };
  sortPickup = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByPickup) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.sub_job_truck_id < b.sub_job_truck_id) {
          return -1;
        }
        if (a.sub_job_truck_id > b.sub_job_truck_id) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByPickup: true });
    }
  };
  sortCrossStreet = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByCrossStreet) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.cross_streets < b.cross_streets) {
          return -1;
        }
        if (a.cross_streets > b.cross_streets) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByCrossStreet: true });
    }
  };
  sortPU = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByPU) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.quarry_site_name < b.quarry_site_name) {
          return -1;
        }
        if (a.quarry_site_name > b.quarry_site_name) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByPU: true });
    }
  };
  sortdelvered = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByDelivered) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.Delivered_on < b.Delivered_on) {
          return -1;
        }
        if (a.Delivered_on > b.Delivered_on) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByDelivered: true });
    }
  };
  sortDeparted = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByDeparted) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.departed_quarry < b.departed_quarry) {
          return -1;
        }
        if (a.departed_quarry > b.departed_quarry) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByDeparted: true });
    }
  };
  sortID = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedById) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.pk < b.pk) {
          return -1;
        }
        if (a.pk > b.pk) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedById: true });
    }
  };
  sortLOT = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByLot) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.lot < b.lot) {
          return -1;
        }
        if (a.lot > b.lot) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByLot: true });
    }
  };
  sortLOTAdress = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByLotAddress) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.lot_address < b.lot_address) {
          return -1;
        }
        if (a.lot_address > b.lot_address) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByLotAddress: true });
    }
  };
  sortCName = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByContract) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.site_person_contact < b.site_person_contact) {
          return -1;
        }
        if (a.site_person_contact > b.site_person_contact) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByContract: true });
    }
  };
  sortPName = () => {
    const { recentOrder } = this.state;
    if (this.state.sortedByName) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.site_person_name < b.site_person_name) {
          return -1;
        }
        if (a.site_person_name > b.site_person_name) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, sortedByName: true });
    }
  };
  Delivered = () => {
    const { recentOrder } = this.state;
    if (this.state.deliverd) {
      this.setState({ recentOrder: recentOrder.reverse() });
    } else {
      var data = recentOrder;
      data.sort((a, b) => {
        if (a.completed < b.completed) {
          return -1;
        }
        if (a.completed > b.completed) {
          return 1;
        }
        return 0;
      });

      this.setState({ recentOrder: data, deliverd: true });
    }
  };

  completeOrder = async (
    sub_job_id,
    truck_id,
    sub_job_truck_id,
    job_detail_id
  ) => {
    const alert = this.props.alert;
    const id = {
      sub_job_id: sub_job_id,
      truck_id: truck_id,
      sub_job_truck_id: sub_job_truck_id,
      job_detail_id: job_detail_id,
    };
    try {
      const response = await axios.post(`${API_URL1}driver/submitJob`, id, {
        headers: {
          "x-access-token": `${localStorage.getItem("TruckertokenKey")}`,
          "content-type": "application/json",
        },
      });

      if (response.data.success) {
        alert.success(response.data.message, {
          onClose: () => {
            window.location = "/dispatched_order";
          },
        });
      }
    } catch (error) {
      alert.error("something went wrong");
    }
  };
  handleSearchChange = (e) => {
    var { nonFilteredData } = this.state;
    let searchQuery = e.target.value;
    if (searchQuery !== "") {
      const Sdata = nonFilteredData;
      var data = Sdata.filter((data) => {
        return (
          (data.po_number &&
            String(data.po_number).toLowerCase().includes(searchQuery)) ||
          (data.pk && String(data.pk).toLowerCase().includes(searchQuery)) ||
          (data.lot && data.lot.toLowerCase().includes(searchQuery)) ||
          (data.lot_address &&
            data.lot_address.toLowerCase().includes(searchQuery)) ||
          (data.quantity &&
            String(data.quantity).toLowerCase().includes(searchQuery)) ||
          (data.color && data.color.toLowerCase().includes(searchQuery)) ||
          (data.customer_site_name &&
            data.customer_site_name.toLowerCase().includes(searchQuery)) ||
          (data.quarry_site_name &&
            data.quarry_site_name.toLowerCase().includes(searchQuery)) ||
          (data.sub_job_truck_id &&
            String(data.sub_job_truck_id)
              .toLowerCase()
              .includes(searchQuery)) ||
          (data.cross_streets &&
            data.cross_streets.toLowerCase().includes(searchQuery))
        );
      });
      this.setState({ recentOrder: data });
    } else {
      this.setState({ recentOrder: nonFilteredData });
    }
  };
  render() {
    const { page, rowsPerPage, recentOrder } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} mb={2}>
          <DeliveredButtons />
          <input
            style={{
              width: "162px",
              marginTop: "28px",
              marginLeft: "15px",
              height: "30px",
            }}
            type="text"
            id="searcheverything"
            className="form-control"
            onChange={this.handleSearchChange}
            placeholder="Search"
          />
        </Grid>

        {!this.state.record && recentOrder.length === 0 ? (
          <div style={{ width: "90%", marginLeft: "3%" }}>Loading.........</div>
        ) : recentOrder.length === 0 && this.state.record ? (
          <div style={{ width: "90%", marginLeft: "3%" }}>
            No Record Available
          </div>
        ) : (
          <Paper style={{ width: "110%", marginLeft: "6%" }}>
            <TableContainer
              sx={{
                background: "#F3F3F3",
                boxShadow: "5px 5px 6px #434344",
                borderRadius: "10px",
              }}
              style={{ height: "65vh", overflow: "scroll" }}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead style={{ backgroundColor: "#000000" }}>
                  <TableRow>
                    <TableCell
                      style={{
                        minWidth: 100,
                        backgroundColor: "#000000",
                        color: "white",
                      }}
                    >
                      Order
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                        minWidth: 100,
                      }}
                    >
                      <i
                        style={{ cursor: "pointer", marginRight: "2px" }}
                        className="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortID}
                      />
                      Order Id
                    </TableCell>

                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                        minWidth: 100,
                      }}
                    >
                      <i
                        style={{ cursor: "pointer", marginRight: "2px" }}
                        className="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortID}
                      />
                      Customer Name 
                    </TableCell>
                  
                  
                 
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                        minWidth: 150,
                      }}
                    >
                      <i
                        style={{ cursor: "pointer", marginRight: "2px" }}
                        className="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortQty}
                      />
                      Qty
                    </TableCell>
                
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                        minWidth: 150,
                      }}
                    >
                      <i
                        style={{ cursor: "pointer", marginRight: "2px" }}
                        className="fa fa-sort"
                        aria-hidden="true"
                      />
                      Load Qty
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 150,
                        backgroundColor: "#000000",
                        color: "white",
                      }}
                    >
                      Truck
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                        minWidth: 150,
                      }}
                    >
                      <i
                        style={{ cursor: "pointer", marginRight: "2px" }}
                        className="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortPickup}
                      />
                    Pick Up #:
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                        minWidth: 150,
                      }}
                    >
                      <i
                        style={{ cursor: "pointer", marginRight: "2px" }}
                        className="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortPU}
                      />
                      P/U Quarry
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                        minWidth: 150,
                      }}
                    >
                      <i
                        style={{ cursor: "pointer", marginRight: "2px" }}
                        className="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortDeparted}
                      />
                      Departed Quarry
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                        minWidth: 150,
                      }}
                    >
                      <i
                        style={{ cursor: "pointer", marginRight: "2px" }}
                        className="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.Delivered}
                      />
                      Delivered at Job
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: 150,
                        backgroundColor: "#000000",
                        color: "white",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ backgroundColor: "#E4E4E4" }}>
                  {recentOrder &&
                    recentOrder
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, key) => {
                        return (
                          <TableRow
                            key={item.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>
                              <OrderDetail
                                pickup_Date={item.to_date}
                                color_name={item.color_name}
                                size={item.size}
                                qty={item.quantity}
                                job_name={item.customer_name}
                                PO_No={item.PO_No}
                                cross_streets={item.cross_streets}
                              />
                            </TableCell>

                            <TableCell style={{ minWidth: 150 }}>
                              {item.order_id}
                            </TableCell>

                          
                            <TableCell style={{ minWidth: 150 }}>
                              {item.customer_name
                                ? item.customer_name
                                : "N/A"}
                            </TableCell>
                           
                            <TableCell>
                              {item.assign_qty
                                ? parseFloat(item.assign_qty).toFixed(
                                    2
                                  )
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {item?.driver_load_qty
                                ? parseFloat(item?.driver_load_qty).toFixed(
                                    2
                                  )
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              <TruckDetail
                                truck={item.registration_number}
                                quarry_site_name={item.quarry_site_name}
                                driver_name={item.driver_name}
                                sub_job_truck_id={item.sub_job_truck_id}
                                phone_no={item.phone_no}
                              />
                            </TableCell>
                            <TableCell>{item.sub_job_truck_id}</TableCell>
                            <TableCell>{item.quarry_site_name}</TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {item.departed_quarry
                                ? moment(
                                    new Date(
                                      new Date(item.departed_quarry).setHours(
                                        new Date(
                                          item.departed_quarry
                                        ).getHours() - 12
                                      )
                                    )
                                  ).format("ll")
                                : "N/A"}
                              <br />
                              {item.departed_quarry
                                ? moment(
                                    new Date(
                                      new Date(item.departed_quarry).setHours(
                                        new Date(
                                          item.departed_quarry
                                        ).getHours() - 12
                                      )
                                    ).setMinutes(
                                      new Date(
                                        item.departed_quarry
                                      ).getMinutes() - 30
                                    )
                                  ).format("h:mm:ss")
                                : ""}
                            </TableCell>
                            <TableCell style={{ minWidth: 150 }}>
                              {item.completed
                                ? moment(
                                    new Date(
                                      new Date(item.completed).setHours(
                                        new Date(item.completed).getHours() - 12
                                      )
                                    )
                                  ).format("ll")
                                : "N/A"}

                              <br />
                              {item.completed
                                ? moment(
                                    new Date(
                                      new Date(item.completed).setHours(
                                        new Date(item.completed).getHours() - 12
                                      )
                                    ).setMinutes(
                                      new Date(item.completed).getMinutes() - 30
                                    )
                                  ).format("h:mm:ss")
                                : ""}
                            </TableCell>

                            <TableCell style={{ width: 160 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: 1,
                                }}
                              >
                                {" "}
                                <NavLink
                                  to={`/deliveredMap/${item.sub_job_truck_id}`}
                                >
                                  <AddLocationAltSharpIcon
                                    style={{ marginRight: "7px" }}
                                  />
                                </NavLink>
                                <DeliveryViewLoadTicket
                                  sub_job_truck_id={item.sub_job_truck_id}
                                />
                                <DownloadPdf pdf={item.upload_map_file} />
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={recentOrder && recentOrder.length}
              page={page}
              onChangePage={this.handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              style={{ fontSize: 16 }}
            />
          </Paper>
        )}
        <div hidden>
          {/* <CurrentLocation /> */}
        </div>
      </Grid>
    );
  }
}

export default withAlert()(Dispatcher);
