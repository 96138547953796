import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select1 from "@mui/material/Select";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { withAlert } from "react-alert";

import axios from "axios";

import { API_URL1 } from "../services/url";
const theme = createTheme();

class CreateQuotation extends React.Component {
  state = { values: [{ value: null }] };
  constructor(props) {
    super(props);

    this.state = {
      Cdata: [],
      customer_site_id: "",
      additional_notes: "",
      customer_site: "",
      paymentDays: 0,
      po_select: "",
      number: "",
      status: "",
      truck_type: "",
      btn_status: "unclicked",

      customer_site_new: "",
      parload: "",
      po_status: false,
      po_error: true,
      quarry_name: "",
      quarry_id: "",
      values: [
        {
          number: "",
          color: "",
          color_id: "",
          size: "",
          size_id: "",
          quantity: "",
          price: "",
          quarry_id: "",
          quarry_name: "",
          delivery_fees: "",
        },
      ],
    };
  }

  onValueChange = (event) => {
    this.setState({ po_select: event.target.value });
  };
  componentDidMount = () => {
    this.getData();
  };

  getData = async () => {
    try {
      const response = await axios.get(
        `${API_URL1}order/quotation/getQuotationDetails?quotation_pk=${this.props.match.params.id}`,
        {
          headers: {
            "x-access-token": `${localStorage.getItem("tokenKey")}`,
            "content-type": "application/json",
          },
        }
      );

      var multiplyOrder = [];
      for (let i = 0; i < response.data.data.length; i++) {
        multiplyOrder.push({
          quantity: response.data.data[i].quantity,
          size_id: response.data.data[i].size_id,
          price: response.data.data[i].price,
          color: response.data.data[i].color,
          size: response.data.data[i].size,
          quarry_id: response.data.data[i].quarry_id,
          quarry_name: response.data.data[i].quarry_name,
          delivery_fees: response.data.data[i].delivery_fees,
        });
      }
      if (multiplyOrder.length === 1) {
        this.setState({ po_select: "one" });
      }
      this.setState({
        values: multiplyOrder,
        customer_site_id: response.data.data[0].customer_site_id,
        truck_type: response.data.data[0].truck_type,
        customer_site_new: response.data.data[0].customer_site_new,
        additional_notes: response.data.data[0].additional_notes,
        status: response.data.data[0].select_delivery,
        quarry_name:
          response.data.data[0].quarry_name === null
            ? "N/A"
            : response.data.data[0].quarry_name,
        quarry_id: response.data.data[0].quarry_id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const alert = this.props.alert;
    try {
      this.setState({ btn_status: "clicked" });
      this.setState({ po_status: false });
      e.preventDefault();
      var multiplyOrder = [];
      for (let i = 0; i < this.state.values.length; i++) {
        multiplyOrder.push({
          quantity: this.state.values[i].quantity,
          material_size_id: this.state.values[i].size_id,
          price: this.state.values[i].price,
          quarry_id: this.state.values[i].quarry_id,
          quarry_name: this.state.values[i].quarry_name,
          delivery_fees: this.state.values[i].delivery_fees,
          number:
            this.state.po_select === "one"
              ? this.state.number
              : this.state.values[i].number,
        });
      }

      const formdata = {
        extra_rate_needed: 0,
        customer_site_id: this.state.customer_site_id,
        payment_days: this.state.paymentDays,
        number: this.state.number,
        items: multiplyOrder,
        delivery: this.state.status,
        truck_type: this.state.truck_type,
        additional_notes: this.state.additional_notes,
        quotation_pk: Number(this.props.match.params.id),
      };

      const response = await axios.post(`${API_URL1}order/job/addPO`, formdata);

      if (response.data.success) {
        alert.success("Added Successfully", {
          onClose: () => {
            window.location = "/active-quotation";
          },
        });
      } else {
        alert.error("Something went wrong", {
          onClose: () => {
            window.location = "/active-quotation";
          },
        });
      }
    } catch (error) {}
  };

  handleChangePO = async (e, i) => {
    this.state.values[i].number = e.target.value;
    this.setState({ values: this.state.values });
    this.setState({ po_status: false });
  };
  handleChangePOOnlyOne = (e) => {
    this.setState({ number: e.target.value });
  };

  render() {
    return (
      <Grid container spacing={2}>
        <Grid xs={12} sm={12}></Grid>
        <Grid xs={11} sm={11} ml={3} mt={5}>
          <Box>
            <Paper
              sx={{
                background: "#F3F3F3",
                boxShadow: "5px 5px 6px #434344",
                borderRadius: "10px",
              }}
              style={{ overflow: "scroll", height: "75vh" }}
            >
              <ThemeProvider theme={theme}>
                <Container component="main">
                  <CssBaseline />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box component="form" noValidate sx={{ mt: 3 }}>
                      <form onSubmit={this.handleSubmit} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={6} sm={3}>
                            <Typography
                              sx={{ mb: 1 }}
                              fontSize="14px"
                              align="left"
                              fontWeight="bold"
                              variant="body2"
                              color="initial"
                            >
                              Delivery Mode
                            </Typography>
                            <TextField
                              sx={{
                                background: "#FFFFFF",
                                boxShadow:
                                  "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "10px!important",
                                border: "none",
                              }}
                              size="small"
                              value={
                                this.state.status === "2"
                                  ? "Delivery"
                                  : this.state.status === "1"
                                  ? "PickUp(FOB)"
                                  : ""
                              }
                              disabled
                              autoComplete="given-name"
                              name="firstName"
                              required
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <Typography
                              sx={{ mb: 1 }}
                              fontSize="14px"
                              align="left"
                              fontWeight="bold"
                              variant="body2"
                              color="initial"
                              value={this.state.major_cross_road}
                              disabled
                            >
                              Customer Site
                            </Typography>
                            <TextField
                              sx={{
                                background: "#FFFFFF",
                                boxShadow:
                                  "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "10px!important",
                                border: "none",
                              }}
                              size="small"
                              value={this.state.customer_site_new}
                              disabled
                              autoComplete="given-name"
                              name="firstName"
                              required
                              fullWidth
                            />
                          </Grid>
                          {this.state.values.length > 1 ? (
                            <Grid item xs={9} sm={5}>
                              <Typography
                                sx={{ mb: 1 }}
                                fontSize="14px"
                                align="left"
                                fontWeight="bold"
                                variant="body2"
                                color="initial"
                              >
                                Whether you want to give 1 po number or multiple
                                po number
                              </Typography>

                              <FormControl fullWidth>
                                <Select1
                                  sx={{
                                    background: "#FFFFFF",
                                    boxShadow:
                                      "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",

                                    border: "none",
                                  }}
                                  size="small"
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  onChange={this.onValueChange}
                                >
                                  <MenuItem value="one">One</MenuItem>
                                  <MenuItem value="multiple">Multiple</MenuItem>
                                </Select1>
                              </FormControl>
                            </Grid>
                          ) : null}

                          {this.state.values &&
                            this.state.values.map((item, key) => (
                              <>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    sx={{ mb: 1 }}
                                    fontSize="14px"
                                    align="left"
                                    fontWeight="bold"
                                    variant="body2"
                                    color="initial"
                                  >
                                    Selected Color
                                  </Typography>
                                  <TextField
                                    sx={{
                                      background: "#FFFFFF",
                                      boxShadow:
                                        "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                      borderRadius: "10px!important",
                                      border: "none",
                                    }}
                                    size="small"
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    value={item.color}
                                    disabled
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    sx={{ mb: 1 }}
                                    fontSize="14px"
                                    align="left"
                                    fontWeight="bold"
                                    variant="body2"
                                    color="initial"
                                  >
                                    Selected Size
                                  </Typography>
                                  <TextField
                                    sx={{
                                      background: "#FFFFFF",
                                      boxShadow:
                                        "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                      borderRadius: "10px!important",
                                      border: "none",
                                    }}
                                    size="small"
                                    disabled
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    value={item.size}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    sx={{ mb: 1 }}
                                    fontSize="14px"
                                    align="left"
                                    fontWeight="bold"
                                    variant="body2"
                                    color="initial"
                                  >
                                    Order Quantity(Tons)
                                  </Typography>
                                  <TextField
                                    sx={{
                                      background: "#FFFFFF",
                                      boxShadow:
                                        "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                      borderRadius: "10px!important",
                                      border: "none",
                                    }}
                                    size="small"
                                    disabled
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    value={
                                      item.quantity
                                        ? parseFloat(item.quantity).toFixed(2)
                                        : "N/A"
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    sx={{ mb: 1 }}
                                    fontSize="14px"
                                    align="left"
                                    fontWeight="bold"
                                    variant="body2"
                                    color="initial"
                                  >
                                    Price
                                  </Typography>
                                  <TextField
                                    sx={{
                                      background: "#FFFFFF",
                                      boxShadow:
                                        "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                      borderRadius: "10px!important",
                                      border: "none",
                                    }}
                                    size="small"
                                    value={item.price}
                                    style={{ fontSize: 16 }}
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    disabled
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    sx={{ mb: 1 }}
                                    fontSize="14px"
                                    align="left"
                                    fontWeight="bold"
                                    variant="body2"
                                    color="initial"
                                  >
                                    Quarry Site Name
                                  </Typography>
                                  <TextField
                                    sx={{
                                      background: "#FFFFFF",
                                      boxShadow:
                                        "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                      borderRadius: "10px!important",
                                      border: "none",
                                    }}
                                    size="small"
                                    value={item.quarry_name}
                                    disabled
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                  />
                                </Grid>
                                {this.state.status == 2 ? (
                                  <Grid item xs={6} sm={3}>
                                    <Typography
                                      sx={{ mb: 1 }}
                                      fontSize="14px"
                                      align="left"
                                      fontWeight="bold"
                                      variant="body2"
                                      color="initial"
                                    >
                                      Partial Load Delivery Fee
                                    </Typography>
                                    <TextField
                                      sx={{
                                        background: "#FFFFFF",
                                        boxShadow:
                                          "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                        borderRadius: "10px!important",
                                        border: "none",
                                      }}
                                      size="small"
                                      disabled
                                      autoComplete="given-name"
                                      name="firstName"
                                      required
                                      value={item.delivery_fees}
                                      fullWidth
                                    />
                                  </Grid>
                                ) : null}
                                {this.state.po_select === "multiple" ? (
                                  <Grid item xs={6} sm={3}>
                                    <Typography
                                      sx={{ mb: 1 }}
                                      fontSize="14px"
                                      align="left"
                                      fontWeight="bold"
                                      variant="body2"
                                      color="initial"
                                    >
                                      PO
                                    </Typography>
                                    <TextField
                                      sx={{
                                        background: "#FFFFFF",
                                        boxShadow:
                                          "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                        borderRadius: "10px!important",
                                        border: "none",
                                      }}
                                      size="small"
                                      autoComplete="given-name"
                                      name="firstName"
                                      value={item.number}
                                      onChange={(e) =>
                                        this.handleChangePO(e, key)
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                ) : null}
                              </>
                            ))}
                          {this.state.po_select === "one" ? (
                            <Grid item xs={6} sm={3}>
                              <Typography
                                sx={{ mb: 1 }}
                                fontSize="14px"
                                align="left"
                                fontWeight="bold"
                                variant="body2"
                                color="initial"
                              >
                                PO
                              </Typography>
                              <TextField
                                sx={{
                                  background: "#FFFFFF",
                                  boxShadow:
                                    "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                  borderRadius: "10px!important",
                                  border: "none",
                                }}
                                size="small"
                                autoComplete="given-name"
                                name="firstName"
                                value={this.state.number}
                                onChange={(e) => this.handleChangePOOnlyOne(e)}
                                fullWidth
                              />
                            </Grid>
                          ) : null}
                          {this.state.status == 2 ? (
                            <Grid align="left" item xs={10} sm={12}>
                              <Typography
                                sx={{ mb: 1 }}
                                fontSize="14px"
                                align="left"
                                fontWeight="bold"
                                variant="body2"
                                color="initial"
                              >
                                Truck/Trailer Type
                              </Typography>
                              <TextField
                                sx={{
                                  background: "#FFFFFF",
                                  boxShadow:
                                    "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                  borderRadius: "10px!important",
                                  border: "none",
                                }}
                                size="small"
                                autoComplete="given-name"
                                name="notes"
                                value={this.state.truck_type}
                                disabled
                                fullWidth
                              />
                            </Grid>
                          ) : null}
                          <Grid align="left" item xs={10} sm={12}>
                            <Typography
                              sx={{ mb: 1 }}
                              fontSize="14px"
                              align="left"
                              fontWeight="bold"
                              variant="body2"
                              color="initial"
                            >
                              Additional Notes
                            </Typography>
                            <TextField
                              sx={{
                                background: "#FFFFFF",
                                boxShadow:
                                  "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "10px!important",
                                border: "none",
                              }}
                              size="small"
                              autoComplete="given-name"
                              name="notes"
                              value={this.state.additional_notes}
                              onChange={(e) => {
                                this.setState({
                                  additional_notes: e.target.value,
                                  truck_status: false,
                                });
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid align="left" item xs={6} sm={3}>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                mt: 2,
                                textTransform: "none",
                                background: "#000000",
                                borderRadius: "10px",
                                width: "70%",
                              }}
                              disabled={
                                this.state.btn_status === "clicked" ||
                                this.state.total_quantity_error === false
                              }
                            >
                              <b>Create Order</b>
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Box>
                </Container>
              </ThemeProvider>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withAlert()(CreateQuotation);
