import React, { Component } from "react";
import { Fragment } from "react";
import BlockData from "./BlockData";
import Trucks_Schedule from "./Trucks_Schedule.js";
import Trucks_Available from "./Trucks_Available.js";
import "./BlockData.css";
import axios from "axios";
import { API_URL1 } from "../services/url";
import { LinearProgress } from "@material-ui/core";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Udata: [],
      Dispatch_today: "",
      Dispatch_week: "",
      Dispatch_year: "",
      tons_today: "",
      tons_week: "",
      tons_year: "",
      hauled_today: "",
      hauled_week: "",
      hauled_year: "",
      record: false,
    };
  }
  async componentDidMount() {
    try {
      var truck_company_id = localStorage.getItem("adminid");

      const response = await axios.get(
        `${API_URL1}trucker/dashboardNew/${truck_company_id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("TruckertokenKey"),
            "content-type": "application/json",
          },
        }
      );
      const response1 = await axios.get(
        `${API_URL1}trucker/dashboardNew/dashboardTruckSchedule/${truck_company_id}`
      );

      if (response.data.truckes) {
        this.setState({
          Udata: response1.data.success.data,
          dispatch_today: response.data.truckes[1][0].dispatch_today,
          dispatch_week: response.data.truckes[1][0].dispatch_week,
          dispatch_year: response.data.truckes[2][0].dispatch_year,
          tons_today: response.data.truckes[3][0].tons_today,
          tons_week: response.data.truckes[4][0].tons_week,
          tons_year: response.data.truckes[5][0].tons_year,
          hauled_today: response.data.truckes[6][0].hauled_today,
          hauled_week: response.data.truckes[7][0].hauled_week,
          hauled_year: response.data.truckes[8][0].hauled_year,
          record: true,
        });
      } else {
        this.setState({ record: true });
      }
    } catch (error) {}
  }
  render() {
    return (
      <Fragment>
        {!this.state.record ? <LinearProgress /> : ""}
        <div
          style={{
            overflow: "scroll",
            height: "88.5vh",
            position: "sticky",
            width: "118.5%",
          }}
        >
          <div style={{ marginTop: "3%" }}>
            <BlockData
              dispatch_today={this.state.dispatch_today}
              dispatch_week={this.state.dispatch_week}
              dispatch_year={this.state.dispatch_year}
              tons_today={this.state.tons_today}
              tons_week={this.state.tons_week}
              tons_year={this.state.tons_year}
              hauled_today={this.state.hauled_today}
              hauled_week={this.state.hauled_week}
              hauled_year={this.state.hauled_year}
              loading={this.state.loading}
            />
          </div>
          <div className="row col-lg-12 d-flex justify-content-around mt-5">
            <Trucks_Available
              Udata={this.state.Udata}
              loading={this.state.loading}
            />
          </div>
          <div className="row col-lg-12 d-flex justify-content-around mt-5">
            <Trucks_Schedule
              Udata={this.state.Udata}
              loading={this.state.loading}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Dashboard;
