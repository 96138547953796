import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./DispatchOrder.css";
import axios from "axios";
import { API_URL1 } from "../services/url";
import moment from "moment";
import { withAlert } from "react-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LinearProgress } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import io from "socket.io-client";
import { SOCKET_URL } from "../services/url";

var socket = io(SOCKET_URL);

const DispatchOrder = withAlert()(({ alert }) => {
  const { id } = useParams();
  const [current_date, setCurrentDate] = useState(
    moment(Date.now()).format().substring(0, 10)
  );
  const [deliveryDate, setDeliveryDate] = useState("");
  const [availTrucks, setAvailTrucks] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [truck_number, setTruckNumber] = useState("");
  const [noOfTruck, setNoOfTruck] = useState([]);
  const [pk, setPk] = useState("");
  const [truck_id, setTruckId] = useState("");
  const [contractor, setcontractor_name] = useState("");
  const [order_date, setOrderDate] = useState("");
  const [po_number, setPoNumber] = useState("");
  const [color_name, setColorName] = useState("");
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [left_quantity, setLeftQuantity] = useState("");
  const [assigned_quantity, setAssignedQuantity] = useState("");
  const [trucker_company_id, setTruckerCompanyId] = useState("");
  const [datePU, setDatePU] = useState("");
  const [quarry, setQuarry] = useState([]);
  const [size_id, setSizeId] = useState("");
  const [type, setType] = useState("");
  const [quarry_name, setQuarryName] = useState("");
  const [quarry_id, setQuarryId] = useState("");
  const [pit_id, setTruck_type] = useState("");
  const [pit_name, setPitName] = useState("");
  const [Udata, setUdata] = useState([]);
  const [truck_quantity, setTruckQuantity] = useState("");
  const [total_quantity_error, setTotalQuantityError] = useState(false);
  const [total_quantity, setTotalQuantity] = useState(0);
  const [job_id, setJobId] = useState("");
  const [btn_clicked, setBtnClicked] = useState("unclick");
  // const [shipped_qty, setShippedQty] = useState("");
  const [capacity_error, setCapacityError] = useState("no");
  const [record, setRecord] = useState(false);
  const [driver_error, setDriverError] = useState("");
  const [address_line_1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [orderData,setOrderData]= useState({});

  useEffect(() => {
    const fetchData = async () => {
      const formdata = {
        acceptStatus: 1,
      };

      try {
        const response1 = await axios.get(
          `${API_URL1}trucker/dispatch/truckRequest1?order_id=${id}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("TruckertokenKey"),
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(response1.data, "This is Response", 
       
        )
        if (response1?.data?.success) {
         setOrderData(response1?.data?.data?.[0])
         setTruckNumber(Math.ceil(Number(response1.data.data[0].order_amt) / 25))
          
        }

        // const formdata1 = {
        //   truck_company_id: localStorage.getItem("adminid"),
        //   dates: moment(new Date()).format("yyyy-MM-DD"),
        // };

        var elements = [];


        for (var i = 0; i < Math.ceil(Number(response1.data.data[0].order_amt) / 25); i++) {
          elements.push({
            truck_id: "",
            driver: "",
            capacity: "",
            quantity: "",
            type: "",
          });
        }
        setNoOfTruck(elements);

        // let str = "";
        // let sum_of_truck_qty = 0;
        // for (let i = 1; i < truck_quantity.length - 1; i++) {
        //   str = str + truck_quantity[i];
        // }
        // let res = str.split(",");

        // let shipped_qty = 0;
        // var arr_data = noOfTruck;
        // for (let j = 0; j < res.length; j++) {
        //   sum_of_truck_qty += Number(res[j]);
        //   arr_data[j].quantity = Number(res[j]);
        //   shipped_qty = shipped_qty + Number(res[j]);
        // }
        // noOfTruck(arr_data);
        // setShippedQty(shipped_qty);
        // setQuantity(sum_of_truck_qty);
      } catch (error) {
        console.error(error);
      }

      try {
        const response = await axios.get(
          `${API_URL1}trucker/driver/getDrivers?truck_company_id=${Number(
            localStorage.getItem("adminid")
          )}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("TruckertokenKey"),
              "content-type": "application/json",
            },
          }
        );
        setUdata(response.data.trucks);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id]);

  const handleTruckById = async (evt, index) => {
    evt.preventDefault();
    if (evt.target.value) {
      let new_arr = [];
      new_arr = evt.target.value.split(",");

      if (Number(new_arr[1]) < noOfTruck[index].quantity) {
        var trips =
          noOfTruck[index].quantity % new_arr[1] !== 0
            ? parseInt(noOfTruck[index].quantity / new_arr[1]) + 1
            : noOfTruck[index].quantity / new_arr[1];
        const updatedArr = [...noOfTruck];
        updatedArr[index].no_of_trips = trips;
        setNoOfTruck(updatedArr);
        setCapacityError("yes");
      } else {
        const updatedArr = [...noOfTruck];
        updatedArr[index].no_of_trips = 1;
        setNoOfTruck(updatedArr);
        setCapacityError("no");
      }
      setTruckId(new_arr[0]);
      let drivername = "";
      let driver_id = "";
      if (new_arr[0] !== "") {
        try {
          const response = await axios.get(
            `${API_URL1}dispatcher/driverName?truck_id=${new_arr[0]}`
          );

          if (response.data.Name[0].name) {
            drivername = response.data.Name[0].name;
            driver_id = response.data.Name[0].truck_company_user_id;
          }
        } catch (error) {
          console.error(error);
        }
      }
      const updatedArr = [...noOfTruck];
      updatedArr[index].truck_id = new_arr[0];
      updatedArr[index].driver = drivername;
      updatedArr[index].driver_id = driver_id;
      updatedArr[index].capacity = new_arr[1];
      updatedArr[index].type = new_arr[2];
      setNoOfTruck(updatedArr);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    let data = [];
    for (let i = 0; i < noOfTruck.length; i++) {
      var mode = noOfTruck[i].quantity % noOfTruck[i].capacity;
      var rem = noOfTruck[i].quantity % noOfTruck[i].capacity;
      if (mode !== 0) {
        for (let k = 0; k < parseInt(noOfTruck[i].no_of_trips - 1); k++) {
          data.push({
            truck_id: Number(noOfTruck[i].truck_id),
            driver_id: Number(noOfTruck[i].driver_id),
            assigned_quantity: Number(noOfTruck[i].capacity),
            no_of_trips: 1,
          });
        }
        data.push({
          truck_id: Number(noOfTruck[i].truck_id),
          driver_id: Number(noOfTruck[i].driver_id),
          assigned_quantity: mode,
          no_of_trips: 1,
        });
      } else {
        for (let k = 0; k < parseInt(noOfTruck[i].no_of_trips); k++) {
          data.push({
            truck_id: Number(noOfTruck[i].truck_id),
            driver_id: Number(noOfTruck[i].driver_id),
            assigned_quantity: Number(noOfTruck[i].capacity),
            no_of_trips: 1,
          });
        }
      }
    }

    let total_quantity = 0;
    for (let j = 0; j < noOfTruck.length; j++) {
      total_quantity += noOfTruck[j].quantity;
    }

    if (total_quantity > orderData?.order_amt) {
      setTotalQuantityError(true);
    } else {
      setTotalQuantityError(false);
    }

    let flag = 0;
    let d_error = false;
    for (let i = 0; i < noOfTruck.length; i++) {
      if (noOfTruck[i].driver === "") {
        flag = 1;
        break;
      }
    }
    if (flag === 0) {
      setDriverError("no");
    } else {
      alert.show("Driver required");
      setDriverError("yes");
      d_error = true;
    }

    if (
      total_quantity <= orderData?.order_amt &&
      d_error === false
    ) {
      console.log("api calls")
      try {
        setBtnClicked("clicked");
        const formdata = {
          order_id: orderData?.order_id,
          quarry_id: orderData?.supplier_id,
          customer_id: orderData?.contractor_id,
          item_name: orderData?.item_name,
          trucks: data,
          left_quantity: orderData?.order_amt - total_quantity,
          company_name: localStorage.getItem("company_name"),
          trucker_email: localStorage.getItem("Temail"),
        };

        const response = await axios.post(
          `${API_URL1}dispatcher/dispatchOrder1`,
          formdata,
          {
            headers: {
              "x-access-token": localStorage.getItem("TruckertokenKey"),
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        if (response.data.success) {
          // socket.emit("send-trucker-assigned-notification", {
          //   truckername: localStorage.getItem("company_name"),
          //   username: "admin",
          //   order_id: po_number,
          // });
          alert.success(response.data.message, {
            onClose: () => {
              window.location = "/dispatcher";
            },
          });
        } else {
          alert.error("Order not assigned", {
            onClose: () => {
              window.location = "/dispatcher";
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


  const handleDate = async (newDate) => {
    setDatePU(newDate);

    const formdata1 = {
      truck_company_id: localStorage.getItem("adminid"),
      dates: moment(newDate).format("yyyy-MM-DD"),
    };
    try {
      const response2 = await axios.post(
        `${API_URL1}trucker/dispatch/availTruck1`,
        formdata1
      );

      let t_type = truck_quantity.split(",");

      let availTrucks = [];
      let flag = 0;
      if (response2.data.Trucks) {
        for (let i = 0; i < response2.data.Trucks.length; i++) {
          for (let j = 0; j < t_type.length; j++) {
            if (t_type[j] === response2.data.Trucks[i].type) {
              flag = 1;
              availTrucks.push(response2.data.Trucks[i]);
            }
          }
        }
        if (flag === 1) {
          setAvailTrucks(availTrucks);
        }

        if (truck_quantity.includes("Any (No restrictions)")) {
          setAvailTrucks(response2.data.Trucks);
        }
        var flag2 = 0;
        for (let i = 0; i < t_type.length; i++) {
          if (
            t_type[i] === "Any (No restrictions)" ||
            t_type[i] === "Flatbed" ||
            t_type[i] === "Live Bottom" ||
            t_type[i] === "10 Wheel" ||
            t_type[i] === "Rock Tub" ||
            t_type[i] === "Super 16" ||
            t_type[i] === "Super 18" || t_type[i] === "End Dump" ||
            t_type[i] === "Belly Dump" ||
            t_type[i] === "Transfer"
          ) {
            flag2 = 1;
          }
        }
        if (flag2 === 0) {
          setAvailTrucks(response2.data.Trucks);
        }
      } else {
        setAvailTrucks([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeQuantity = (index, evt) => {
    const qty = Number(evt.target.value);
    const updatedArr = [...noOfTruck];
    updatedArr[index].quantity = qty;
    setBtnClicked(false)

    var trips =
      qty % updatedArr[index].capacity !== 0
        ? parseInt(qty / updatedArr[index].capacity) + 1
        : qty / updatedArr[index].capacity;

    updatedArr[index].no_of_trips = trips;
    setNoOfTruck(updatedArr);

    if (Number(evt.target.value) <= Number(updatedArr[index].capacity)) {
      setCapacityError("no");
    } else {
      setCapacityError("yes");
    }
  };

  const handleDriver = (index, evt) => {
    const res = evt.target.value.split(",");
    const updatedArr = [...noOfTruck];
    updatedArr[index].driver = res[0];
    setNoOfTruck(updatedArr);
  };

  const oneYearFromNow = new Date();
  const after_oneyear = oneYearFromNow.setFullYear(
    oneYearFromNow.getFullYear() + 1
  );

  console.log(noOfTruck, "noOfTruck",truck_number)

  return (
    <Fragment>
      {!record ? <LinearProgress /> : ""}
      <Link to={`/dispatcher`}>
        <Button
          style={{
            marginTop: "2%",
            marginLeft: "450px",
            marginBottom: "10px",
            width: "132px",
            color: "white",
            backgroundColor: "#114C65",
            borderRadius: "25px",
          }}
        >
          Dispatch Order
        </Button>
      </Link>

      <div
        className="mb-5 main__body d-flex"
        style={{
          overflow: "scroll",
          height: "75vh",
          marginLeft: "95px",
          width: "70vw",
          padding: "20px",
        }}
      >
        <div className="row container main__form col-lg-12">
          <form onSubmit={handleSubmit}>
            <div className="row form-group div_small">
              <label className="col-lg-4 pt-2" htmlFor="OrderID">
                Order:
              </label>
              <input
                className="form-control col-lg-5"
                disabled
                aria-describedby="textHelp"
                required
                placeholder="Order"
                value={orderData?.order_id}
              />
            </div>
            <div></div>
            <div className="row form-group div_small">
              <label className="col-lg-4 pt-2" htmlFor="OrderID">
                PO:
              </label>
              <input
                className="form-control col-lg-5"
                disabled
                aria-describedby="textHelp"
                required
                placeholder="PO"
                value={orderData?.po_no}
              />
            </div>
            <div className="row form-group div_small">
              <label className="col-lg-4 pt-2" htmlFor="OrderID">
                Address:
              </label>
              <input
                className="form-control col-lg-5"
                disabled
                aria-describedby="textHelp"
                required
                placeholder="PO"
                value={`${orderData?.addresses}`}
              />
            </div>
         
            <div className="row form-group div_small ">
              <label className="col-lg-4 pt-2" htmlFor="OrderID">
                Items:
              </label>
              <input
                className="form-control col-lg-5"
                disabled
                aria-describedby="textHelp"
                required
                placeholder="Color"
                value={orderData?.item_name}
              />
            </div>
           
            <div className="row form-group div_small ">
              <label className="col-lg-4 pt-2" htmlFor="OrderID">
                P/U Quarry
              </label>

              <input
                className="form-control col-lg-5"
                disabled
                aria-describedby="textHelp"
                required
                placeholder="P/U Quarry"
                value={orderData?.supplier}
              />
            </div>
            <div className="row form-group div_small ">
              <label className="col-lg-4 pt-2" htmlFor="OrderID">
                T/T Requirement
              </label>
              <input
                className="form-control col-lg-5"
                disabled
                aria-describedby="textHelp"
                required
                placeholder="T/T Requirement"
                value={orderData?.truck_type}
              />
            </div>
            <div className="row form-group div_small ">
              <label className="col-lg-4 pt-2" htmlFor="OrderID">
                Amount to be Dispatched:
              </label>
              <input
                className="form-control col-lg-5"
                disabled
                aria-describedby="textHelp"
                placeholder="Original Qty"
                value={
                  orderData?.order_amt
                    ? parseFloat(orderData?.order_amt).toFixed(2)
                    : "N/A"
                }
              />
            </div>
            {/* <div className="row form-group div_small ">
              <label className="col-lg-4 pt-2" htmlFor="OrderID">
                Desired Delivery Date
              </label>
              <input
                className="form-control col-lg-5"
                disabled
                aria-describedby="textHelp"
                required
                placeholder="Shipped Qty"
                value={
                  deliveryDate
                    ? moment(deliveryDate).format("ll")
                    : "N/A"
                }
              />
            </div> */}
            <div className="row form-group div_small ">
              <label className="col-lg-3" htmlFor="OrderID">
                Date to P/U
              </label>
              <div
                style={{ marginLeft: "57px", width: "90%" }}
                className="col-lg-6"
              >
                <DatePicker
                  minDate={new Date()}
                  maxDate={after_oneyear}
                  placeholderText="mm-dd-yyyy"
                  className="form-control"
                  showPopperArrow={false}
                  selected={datePU}
                  onChange={handleDate}
                  dateFromat="YYYY-MM-dd"
                  required
                />
              </div>
            </div>

            {datePU != ""  &&(
              <div className="col-lg-9 div_small d-flex"><label className="col-lg-2 mr-1">Truck</label>
                <label className="col-lg-2 mr-1">Driver</label>
                <label className="col-lg-3 ml-5 ">Truck/Trailer type</label>
                <label className="col-lg-2 ml-4">Capacity</label>
                <label className="col-lg-2 ">Quantity</label>
                <label className="col-lg-2 ">No of trips</label>
              </div>
            )}

            {
              noOfTruck && noOfTruck.map((truck, key) => {
                return (
                  <>
                    <div className="col-lg-9 div_small d-flex">
                      <label className="mr-1">{key + 1}</label>
                      <select
                        required
                        style={{ height: "35px" }}
                        placeholder="Select Query"
                        className="form-control col-lg-2 mr-1 "
                        onChange={(e) => {
                          handleTruckById(e, key);
                        }}
                      >
                        <option value="" required>
                          Select
                        </option>
                        {availTrucks &&
                          availTrucks.map((availTrucks, key) => {
                            return availTrucks.registration_number !== null ? (
                              <option
                                key={key}
                                value={[
                                  availTrucks.truck_id,
                                  availTrucks.capacity,
                                  availTrucks.type,
                                ]}
                              >
                                {availTrucks.registration_number}
                              </option>
                            ) : (
                              ""
                            );
                          })}
                      </select>
                      <input
                        style={{ marginRight: "-5px", borderRight: "none" }}
                        className="col-lg-2 form-control impinput "
                        disabled
                        aria-describedby="textHelp"
                        required
                        placeholder="Driver"
                        value={noOfTruck[key].driver}
                      />
                      <select
                        style={{ height: "35px" }}
                        className="form-control  mr-1"
                        onChange={(e) => {
                          handleDriver(key, e);
                        }}
                      >
                        <option value={noOfTruck[key].driver}></option>
                        {Udata &&
                          Udata.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={[
                                  item.name,
                                  item.truck_id,
                                  item.phone_no,
                                  item.truck_company_user_id,
                                  item.type,
                                ]}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                      <input
                        className="col-lg-3 form-control mr-1"
                        disabled
                        aria-describedby="textHelp"
                        required
                        placeholder="Truck/Trailer Type"
                        value={noOfTruck[key].type}
                      />
                      <div className="col-lg-2">
                        <input
                          style={{ width: "85px" }}
                          className=" form-control mr-1 "
                          aria-describedby="textHelp"
                          required
                          disabled
                          placeholder="Capacity"
                          value={noOfTruck[key].capacity}
                        />
                        {noOfTruck[key].capacity !== "" &&
                          Number(noOfTruck[key].quantity) >
                          Number(noOfTruck[key].capacity) ? (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            Capacity &#10095;= Quantity{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <input
                        className="col-lg-2 form-control mr-1 "
                        aria-describedby="textHelp"
                        required
                        placeholder="Quantity"
                        onChange={(evt) => {
                          handleChangeQuantity(key, evt);
                        }}
                        value={noOfTruck[key].quantity}
                      />
                      <input
                        className="col-lg-2 form-control mr-1 "
                        aria-describedby="textHelp"
                        required
                        placeholder="No of trips"
                        value={noOfTruck[key].no_of_trips}
                      />
                    </div>
                  </>
                );
              })}
            <div className="col-lg-8 div_small d-flex">
              {total_quantity_error ? (
                <p style={{ color: "red" }}>
                  must be less then shipped quantity
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-8 div_small d-flex">
              <Button
                type="submit"
                style={{
                  float: "left",
                  marginTop: "3%",
                  marginLeft: "280px",
                  marginBottom: "15px",
                  width: "165px",
                  color: "white",
                  backgroundColor: "#114C65",
                  borderRadius: "25px",
                }}
                disabled={btn_clicked === "clicked"}
              >
                Schedule Dispatch
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
});

export default DispatchOrder;
