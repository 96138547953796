import * as React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { API_URL1 } from "../services/url";
import axios from "axios";
import { useAlert } from "react-alert";
import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";

function SimpleDialog(props) {
  const { onClose, open, data } = props;
  const alert = useAlert();
  const [update, setupdate] = useState(false);
  const [btn_status, setBtn_Status] = React.useState("");

  const handleDelete = async (e) => {
    setBtn_Status("clicked");

    setupdate(!update);
    try {
      const res = await axios.put(
        `${API_URL1}trucker/driver/deleteDriver/${data}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (res.data.success) {
        handleClose();
        alert.success(res.data.message, {
          onClose: () => {
            window.location.reload();
          },
        });
      } else {
        handleClose();
        alert.show(`cannot delete driver ${res.data.message}`, {
          onClose: () => {
            window.location.reload();
          },
        });
      }
    } catch (error) {
      alert.error(error);
    }
  };
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Paper
        sx={{
          background: "#554D4D",
          borderadRius: "30px",
          width: "100%",
        }}
      >
        <DialogTitle
          sx={{ background: "#554D4D", color: "#fff", textAlign: "center" }}
        >
          Delete Driver
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem
            autoFocus
            sx={{ backgroundColor: "white", width: "100%", mb: 2 }}
          >
            <Box>
              <Typography
                sx={{ margin: 5 }}
                fontWeight="bold"
                variant="body1"
                color="initial"
              >
                Are you sure you want to delete this driver?
              </Typography>
              <Box sx={{ display: "flex", marginTop: "20px" }}>
                <Button
                  style={{
                    color: "white",
                    width: "30%",
                    background: "#E30606",
                    borderRadius: "20px",
                    textTransform: "none",
                    marginLeft: "10px",
                  }}
                  variant="text"
                  color="primary"
                  disabled={btn_status === "clicked"}
                  onClick={handleDelete}
                >
                  <b>Yes</b>
                </Button>
                <Button
                  style={{
                    color: "white",
                    width: "30%",
                    background: "#737272",
                    borderRadius: "20px",
                    textTransform: "none",
                    marginLeft: "50px",
                  }}
                  variant="text"
                  color="primary"
                  onClick={() => handleClose()}
                >
                  <b>No</b>
                </Button>
              </Box>
            </Box>
          </ListItem>
        </List>
      </Paper>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dat: PropTypes.object.isRequired,
};

export default function DeleteDriver(props) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <AiFillDelete
        style={{
          color: "red",
          marginLeft: "15px",
          fontSize: "17px",
          cursor: "pointer",
        }}
        variant="none"
        onClick={handleClickOpen}
      >
        <DeleteOutlinedIcon />
      </AiFillDelete>

      <SimpleDialog open={open} data={data} onClose={handleClose} />
    </>
  );
}
