import * as React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import {
  List,
  ListItem,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  Typography,
} from "@mui/material/";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import { API_URL1 } from "../services/url";
import { useAlert } from "react-alert";
// import io from "socket.io-client";
// import { SOCKET_URL } from "../services/url";
// var socket = io(SOCKET_URL);
// const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [btn_status, setBtn_Status] = React.useState("");
  const alert = useAlert();
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleDelete = async () => {
    setBtn_Status("clicked");
    var data = {
      company_name: localStorage.getItem("company_name"),
      trucker_email: localStorage.getItem("Temail"),
    };
    try {
      const res = await axios.put(
        `${API_URL1}trucker/dispatch/canceltruckRequest/${props.job_detail_id}/${props.truck_request_id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (res.data.success) {
        handleClose();
        // socket.emit("send-cancel-order-byTrucker-notification", {
        //   truckername: localStorage.getItem("company_name"),
        //   username: "admin",
        //   po_number: props.po_number,
        // });
        alert.success(res.data.message, {
          onClose: () => {
            window.location.reload();
          },
        });
      } else {
        alert.error(res.data.message);
      }
    } catch (error) {
      alert.error(error);
    }
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <Paper
        sx={{
          background: "#554D4D",
          borderadRius: "30px",
          width: "100%",
        }}
      >
        <DialogTitle
          align="center"
          sx={{ background: "#554D4D", color: "#fff" }}
        >
          Cancel Request
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem
            autoFocus
            sx={{ backgroundColor: "white", width: "100%", mb: 2 }}
          >
            <Box>
              <Typography
                sx={{ margin: 5 }}
                fontWeight="bold"
                variant="body1"
                color="initial"
              >
                Are you sure you want to cancel this order?
              </Typography>
              <Box sx={{ display: "flex", marginTop: "20px" }}>
                <Button
                  style={{
                    color: "white",
                    width: "30%",
                    background: "#E30606",
                    borderRadius: "20px",
                    textTransform: "none",
                    marginLeft: "30px",
                  }}
                  variant="text"
                  color="primary"
                  disabled={btn_status === "clicked"}
                  onClick={() => handleDelete()}
                >
                  <b>Yes</b>
                </Button>
                <Button
                  style={{
                    color: "white",
                    width: "20%",
                    background: "#737272",
                    borderRadius: "20px",
                    textTransform: "none",
                    marginLeft: "50px",
                  }}
                  variant="text"
                  color="primary"
                  onClick={() => handleClose()}
                >
                  <b>No</b>
                </Button>
              </Box>
            </Box>
          </ListItem>
        </List>
      </Paper>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <DeleteOutlineIcon
        onClick={handleClickOpen}
        style={{ color: "red", marginRight: "15px", cursor: "pointer" }}
      />

      <SimpleDialog
        truck_request_id={props.truck_request_id}
        job_detail_id={props.job_detail_id}
        po_number={props.po_number}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
