import { Grid } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import { Typography } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API_URL1 } from "../services/url";
import axios from "axios";
import { LinearProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteComplain from "./DeleteComplain";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
const ViewComplain = (props) => {
  const [Sdata, setSdata] = useState([]);
  const [record, setrecord] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(100);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const [tokenvalue, setTokenValue] = useState(
    localStorage.getItem("TruckertokenKey")
  );
  const [trucker_id, setTrucker_ID] = useState(
    localStorage.getItem("trucker_id")
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL1}trucker/complain?trucker_id=${trucker_id}`,
          {
            params: {
              trucker_id: localStorage.getItem("user"),
            },
            headers: {
              "x-access-token": `${tokenvalue}`,
              "content-type": "application/json",
            },
          }
        );

        if (response.data.complain) {
          setSdata(response.data.complain);
          setrecord(true);
        } else {
          setSdata([]);
          setrecord(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Fragment>
      {!record ? <LinearProgress /> : ""}
      <br />
      <Grid container style={{ marginLeft: "5rem" }}>
        <Grid item xs={1} sm={1}>
          <Link to="#">
            <Button
              variant="contained"
              width="145px"
              style={{
                // marginTop: "3%",
                // marginLeft: "-1rem",
                backgroundColor: "#114C65",
                borderRadius: "20px",
                maxWidth: "145px",
              }}
            >
              <Typography color="white">Comments</Typography>
            </Button>
          </Link>
        </Grid>
        {/* <Grid item xs={8} sm={4}></Grid> */}
        <Grid item xs={2} sm={2}>
          <Link to="/complain">
            <Button
              variant="contained"
              width="145px"
              style={{
                // marginTop: "3%",
                marginLeft: "2rem",
                backgroundColor: "#000",
                borderRadius: "20px",
                float: "left",
              }}
            >
              <Typography color="white"> Add a comment</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <br />

      {!record && Sdata.length === 0 ? (
        <div style={{ marginLeft: "4rem" }}>Loading.........</div>
      ) : Sdata.length === 0 && record ? (
        <div style={{ marginLeft: "8%" }}>No Record Available</div>
      ) : (
        <div
          className="table"
          style={{ marginLeft: "4rem", marginRight: "15px" }}
        >
          <Paper style={{ width: "110%" }}>
            <TableContainer
              style={{
                background: "#F3F3F3",

                height: "60vh",

                maxHeight: 450,
              }}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead style={{ backgroundColor: "#000000" }}>
                  <TableRow>
                    <TableCell
                      style={{ backgroundColor: "#000000", color: "white" }}
                      align="center"
                    >
                      Comment Type
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                      }}
                      align="center"
                    >
                      Description
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                      }}
                      align="center"
                    >
                      Comment Date
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                      }}
                      align="center"
                    >
                      Comment Resolution
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#000000",
                        color: "white",
                      }}
                      align="center"
                    >
                      Delete Comments
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  style={{
                    backgroundColor: "#E4E4E4",
                  }}
                >
                  {Sdata != 0 ? (
                    Sdata &&
                    Sdata.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((item, key) => (
                      <TableRow key={item.name}>
                        <TableCell align="center">
                          {item.complain_type}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {item.description}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {item.complain_date
                            ? moment(item.complain_date).format("ll")
                            : "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {item.complaint_resolution}
                        </TableCell>
                        <TableCell align="center">
                          <DeleteComplain complain_id={item.complain_id} />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <b>No Comment</b>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={Sdata && Sdata.length}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              style={{ fontSize: 16 }}
            />
          </Paper>
        </div>
      )}
    </Fragment>
  );
};

export default ViewComplain;
