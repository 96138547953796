import * as React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

function SimpleDialog(props) {
  const {
    open,
    onClose,
    quarry_site_name,
    truck,
    driver_name,
    phone_no,
    sub_job_truck_id,
  } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} width={50}>
      <Paper
        sx={{
          background: "#554D4D",
          borderadRius: "30px",
          width: "100%",
        }}
      >
        <DialogTitle sx={{ background: "#554D4D", color: "#fff" }}>
          Truck Details
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem
            autoFocus
            sx={{ backgroundColor: "white", width: "100%", mb: 2 }}
          >
            <Box>
              <div>
                <p>
                  {" "}
                  <b>Truck :</b> {truck}
                </p>
                <p>
                  {" "}
                  <b>Driver Name : </b>
                  {driver_name}
                </p>
                <p>
                  {" "}
                  <b>Driver Mobile : </b>
                  {phone_no}
                </p>
                <p>
                  {" "}
                  <b>Pick-Up #: </b>
                  {sub_job_truck_id}
                </p>
                <p>
                  {" "}
                  <b> Pick-Up Quarry: </b>
                  {quarry_site_name}
                </p>
              </div>
              <ListItemText sx={{ backgroundColor: "#554D4D" }} primary="" />
            </Box>
          </ListItem>
        </List>
      </Paper>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo(props) {
  const { quarry_site_name, truck, driver_name, phone_no, sub_job_truck_id } =
    props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (evt) => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      <VisibilityOutlinedIcon
        style={{ color: "#0075FF" }}
        onClick={handleClickOpen}
      />

      <SimpleDialog
        open={open}
        truck={truck}
        onClose={handleClose}
        driver_name={driver_name}
        phone_no={phone_no}
        sub_job_truck_id={sub_job_truck_id}
        quarry_site_name={quarry_site_name}
      />
    </div>
  );
}
