import React, { Fragment, Component } from "react";
import axios from "axios";
import { API_URL1 } from "../services/url";
import TablePagination from "@material-ui/core/TablePagination";
import { TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import { withAlert } from "react-alert";
import { LinearProgress } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import "./Availability.css";
class ViewDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Udata: [],
      tokenvalue: localStorage.getItem("TruckertokenKey"),
      days: ["", "", "", "", "", "", "", "", "", ""],
      ways: ["", "", "", "", "", "", "", "", "", ""],
      page: 0,
      rowPerPage: 100,
      pageData: [],
      values: [{ truck_id: "", available: "", available_id: "" }],
      val: [],
      truckstaus: 0,
      colDATA: "",
      rowDATA: "",
      btn_Status: "unclick",
      record: false,
    };
    this.handleChangeAvailable = this.handleChangeAvailable.bind(this);
  }

  handleChangePage = (newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowPerPage: parseInt(event.target.value) });
    this.setState({ page: 0 });
  };

  async componentDidMount() {
    try {
      var truck_company_id = localStorage.getItem("adminid");

      const response = await axios.get(
        `${API_URL1}trucker/truckAvailability/truck_insert/${truck_company_id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("TruckertokenKey"),
            "content-type": "application/json",
          },
        }
      );
      if (response.data.success.data) {
        this.setState({
          Udata: response.data.success.data,
          record: true,
        });
      } else {
        this.setState({
          Udata: [],
          record: true,
        });
      }

      var multiOrdercol = [];
      for (var i = 0; i < 10; i++) {
        multiOrdercol.push([]);
      }
      var multiOrderRow = [];
      for (var i = 0; i < response.data.success.data.length; i++) {
        multiOrderRow.push([]);
      }

      this.setState({ colDATA: multiOrdercol, rowDATA: multiOrderRow });
    } catch (error) {
      console.log("Error", error);
    }
    let d = "";
    let w = "";
    let week = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    for (var i = 0; i < 14; i++) {
      const t1 = new Date();
      const t2 = new Date();
      const t3 = new Date();
      const t4 = new Date();

      if (
        week[new Date(t1.setDate(t1.getDate() + i)).getDay()] === "Sunday" ||
        week[new Date(t2.setDate(t2.getDate() + i)).getDay()] === "Saturday"
      ) {
      } else {
        d = d + "," + week[new Date(t3.setDate(t3.getDate() + i)).getDay()];
        w = w + "," + new Date(t4.setDate(t4.getDate() + i));
      }
    }
    var a = [];
    var w1 = [];
    w1 = w.split(",");
    a = d.split(",");
    for (let i = 1; i < a.length; i++) {
      this.state.days[i] = a[i];
      this.state.ways[i] = moment(w1[i]).format("ll");
      this.setState({
        days: this.state.days,
        ways: this.state.ways,
      });
    }
  }

  emptyRows = () => {
    return (
      this.state.rowPerPage -
      Math.min(
        this.state.rowPerPage,
        this.state.rows.length - this.state.page * this.state.rowsPerPage
      )
    );
  };
  handleChangeAvailable = (index, id, available, j, available_id) => {
    let data = { truck_id: id, available: 1, available_id: available_id };
    let val1 = this.state.val;
    let Udata = this.state.Udata;

    if (val1.length === 0) {
      if (Udata[index].data[j]) {
        val1.push(data);
        Udata[index].data[j].available = 1;
      }
    } else {
      let flag = 0;
      if (Udata[index].data[j]) {
        for (let i = 0; i < val1.length; i++) {
          if (val1[i].available_id === available_id) {
            if (val1[i].available === 0) {
              val1[i].available = 1;
              Udata[index].data[j].available = 1;
              this.setState({ Udata: Udata });
              flag = 1;
              break;
            } else {
              val1[i].available = 0;
              flag = 1;
              Udata[index].data[j].available = 0;
              this.setState({ Udata: Udata });

              break;
            }
          }
        }
      }
      if (flag === 0) {
        if (Udata[index].data[j]) {
          val1.push(data);
          Udata[index].data[j].available = 1;
          this.setState({ Udata: Udata });
        }
      }
    }
    this.setState({ val: val1 });
  };

  handleSave = async () => {
    let radio_button_data = [];
    let colDATA = this.state.colDATA;
    let rowDATA = this.state.rowDATA;
    let val = this.state.val;
    this.setState({ btn_Status: "click" });
    for (let k = 0; k < colDATA.length; k++) {
      for (let l = 0; l < colDATA[k].length; l++) {
        radio_button_data.push({
          truck_id: colDATA[k][l].truck_id,
          available: colDATA[k][l].available,
          available_id: colDATA[k][l].available_id,
        });
      }
    }
    for (let k = 0; k < rowDATA.length; k++) {
      for (let l = 0; l < rowDATA[k].length; l++) {
        radio_button_data.push({
          truck_id: rowDATA[k][l].truck_id,
          available: rowDATA[k][l].available,
          available_id: rowDATA[k][l].available_id,
        });
      }
    }
    for (let k = 0; k < this.state.val.length; k++) {
      radio_button_data.push({
        truck_id: val[k].truck_id,
        available: val[k].available,
        available_id: val[k].available_id,
      });
    }

    try {
      const response = await axios.post(
        `${API_URL1}trucker/truckAvailability/editupdate/${localStorage.getItem(
          "adminid"
        )}`,
        radio_button_data,
        {
          headers: {
            "x-access-token": localStorage.getItem("TruckertokenKey"),
            "content-type": "application/json",
          },
        }
      );

      const alert = this.props.alert;
      if (response.data.success) {
        alert.success("CHANGES SAVED SUCCESSFULLY!", {
          onClose: () => {
            window.location = "/truck_Availability";
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleColumn = (index, e) => {
    let Udata = this.state.Udata;

    let val = [];
    if (e.target.checked) {
      for (let j = 0; j < Udata.length; j++) {
        if (Udata[j].data[index]) {
          Udata[j].data[index].available = 1;
          val.push({
            truck_id: Udata[j].truck_id,
            available: 1,
            available_id: Udata[j].data[index].available_id,
          });
        }
      }
    } else {
      for (let j = 0; j < Udata.length; j++) {
        if (Udata[j].data[index]) {
          Udata[j].data[index].available = 0;
          val.push({
            truck_id: Udata[j].truck_id,
            available: 0,
            available_id: Udata[j].data[index].available_id,
          });
        }
      }
    }
    let coldata = this.state.colDATA;
    coldata[index] = val;
    this.setState({ colDATA: coldata });
  };

  handleBack = () => {
    window.location = "/";
  };

  handleRow = (index, e) => {
    let Udata = this.state.Udata;

    let val = [];
    if (e.target.checked) {
      for (let j = index; j < index + 1; j++) {
        for (let k = 0; k < Udata[j].data.length; k++) {
          Udata[j].data[k].available = 1;
          val.push({
            truck_id: Udata[j].truck_id,
            available: 1,
            available_id: Udata[j].data[k].available_id,
          });
        }
      }
    } else {
      for (let j = index; j < index + 1; j++) {
        for (let k = 0; k < Udata[j].data.length; k++) {
          Udata[j].data[k].available = 0;
          val.push({
            truck_id: Udata[j].truck_id,
            available: 0,
            available_id: Udata[j].data[k].available_id,
          });
        }
      }
    }
    let rowdata = this.state.rowDATA;
    rowdata[index] = val;
    this.setState({ rowDATA: rowdata });
  };
  render() {
    return (
      <Fragment>
        {!this.state.record ? <LinearProgress /> : ""}
        <>
          {!this.state.record && this.state.Udata.length === 0 ? (
            <div style={{ marginLeft: "8%", marginTop: "5%" }}>
              Loading.........
            </div>
          ) : this.state.Udata.length === 0 && this.state.record ? (
            <div style={{ marginLeft: "8%", marginTop: "5%" }}>
              No Record Available
            </div>
          ) : (
            <>
              {" "}
              <div className="container mt-4 driver">
                <div className="row ml-5">
                  <div className="col-lg-12 ">
                    <div className="navbar-nav navbar-expand-lg navbar-light">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className=""
                        id="navbarNav"
                      >
                        <ul className="navbar-nav">
                          <Button
                            style={{
                              width: "132px",
                              color: "white",
                              backgroundColor: "#114C65",
                              borderRadius: "25px",
                            }}
                            disabled={this.state.btn_Status === "click"}
                            onClick={this.handleSave}
                          >
                            {" "}
                            Save
                          </Button>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container ml-3">
                <div className="row text-center pt-3 px-s mx-5">
                  <Paper style={{ width: "120%" }}>
                    <TableContainer
                      sx={{
                        background: "#F3F3F3",
                        boxShadow: "5px 5px 6px #434344",
                        borderRadius: "10px",
                      }}
                      style={{ height: "60vh" }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead
                          style={{
                            backgroundColor: "#000000",
                          }}
                        >
                          <TableRow
                            style={{
                              backgroundColor: "#000000",
                              color: "white",
                            }}
                          >
                            <TableCell
                              align="left"
                              style={{
                                left: 0,

                                minWidth: 100,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              <i class="fa fa-sort" aria-hidden="true" />
                              Truck
                            </TableCell>
                            <TableCell
                              style={{
                                left: 100,
                                minWidth: 150,

                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              Truck/Trailer Type
                            </TableCell>
                            <TableCell
                              style={{
                                left: 250,
                                minWidth: 150,

                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              Max Tons Hauled{" "}
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            ></TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[1]
                                ? this.state.days[1] + " " + this.state.ways[1]
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[2]
                                ? this.state.days[2] + " " + this.state.ways[2]
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[3]
                                ? this.state.days[3] + " " + this.state.ways[3]
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[4]
                                ? this.state.days[4] + " " + this.state.ways[4]
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[5]
                                ? this.state.days[5] + " " + this.state.ways[5]
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[6]
                                ? this.state.days[6] + " " + this.state.ways[6]
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[7]
                                ? this.state.days[7] + " " + this.state.ways[7]
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[8]
                                ? this.state.days[8] + " " + this.state.ways[8]
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[9]
                                ? this.state.days[9] + " " + this.state.ways[9]
                                : ""}
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: 180,
                                backgroundColor: "#000000",
                                color: "white",
                              }}
                            >
                              {this.state.days[10]
                                ? this.state.days[10] +
                                  " " +
                                  this.state.ways[10]
                                : ""}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              align="left"
                              style={{
                                left: 0,

                                minWidth: 100,
                              }}
                            ></TableCell>
                            <TableCell
                              align="left"
                              style={{
                                left: 100,

                                minWidth: 150,
                              }}
                            ></TableCell>
                            <TableCell
                              align="left"
                              style={{
                                left: 250,

                                minWidth: 100,
                              }}
                            ></TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(0, evt);
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(1, evt);
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(2, evt);
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(3, evt);
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(4, evt);
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(5, evt);
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(6, evt);
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(7, evt);
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(8, evt);
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              {" "}
                              <input
                                type="checkbox"
                                onClick={(evt) => {
                                  this.handleColumn(9, evt);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ backgroundColor: "#E4E4E4" }}>
                          {this.state.Udata &&
                            this.state.Udata.slice().map((item, index) => (
                              <TableRow
                                hover
                                role="checkbox"
                                key={index}
                                tabIndex={-1}
                              >
                                <TableCell
                                  align="left"
                                  style={{
                                    left: 0,
                                    backgroundColor: "#E4E4E4",

                                    minWidth: 100,
                                  }}
                                >
                                  {item.registration_number !== null
                                    ? item.registration_number
                                    : "N/A"}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    left: 100,
                                    minWidth: 150,
                                    backgroundColor: "#E4E4E4",
                                  }}
                                >
                                  {item.truck_type !== null
                                    ? item.truck_type
                                    : "N/A"}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    left: 250,
                                    minWidth: 100,
                                    backgroundColor: "#E4E4E4",
                                  }}
                                >
                                  {item.capacity
                                    ? parseFloat(item.capacity).toFixed(2)
                                    : "N/A"}
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="checkbox"
                                    onClick={(evt) => {
                                      this.handleRow(index, evt);
                                    }}
                                  />
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[0]
                                          ? item.data[0].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[0]
                                            ? item.data[0].available
                                            : "",
                                          0,
                                          item.data[0]
                                            ? item.data[0].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[1]
                                          ? item.data[1].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[1]
                                            ? item.data[1].available
                                            : "",
                                          1,
                                          item.data[1]
                                            ? item.data[1].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[2]
                                          ? item.data[2].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[2]
                                            ? item.data[2].available
                                            : "",
                                          2,
                                          item.data[2]
                                            ? item.data[2].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[3]
                                          ? item.data[3].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[3]
                                            ? item.data[3].available
                                            : "",
                                          3,
                                          item.data[3]
                                            ? item.data[3].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[4]
                                          ? item.data[4].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[4]
                                            ? item.data[4].available
                                            : "",
                                          4,
                                          item.data[4]
                                            ? item.data[4].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[5]
                                          ? item.data[5].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[5]
                                            ? item.data[5].available
                                            : "",
                                          5,
                                          item.data[5]
                                            ? item.data[5].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[6]
                                          ? item.data[6].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[6]
                                            ? item.data[6].available
                                            : "",
                                          6,
                                          item.data[6]
                                            ? item.data[6].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[7]
                                          ? item.data[7].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[7]
                                            ? item.data[7].available
                                            : "",
                                          7,
                                          item.data[7]
                                            ? item.data[7].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[8]
                                          ? item.data[8].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[8]
                                            ? item.data[8].available
                                            : "",
                                          8,
                                          item.data[8]
                                            ? item.data[8].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell style={{ minWidth: 180 }}>
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={
                                        item.data[9]
                                          ? item.data[9].available === 1
                                          : ""
                                      }
                                      onClick={(evt) => {
                                        this.handleChangeAvailable(
                                          index,
                                          item.truck_id,
                                          item.data[9]
                                            ? item.data[9].available
                                            : "",
                                          9,
                                          item.data[9]
                                            ? item.data[9].available_id
                                            : ""
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          {this.emptyRows > 0 && (
                            <TableRow style={{ height: "59" * this.emptyRows }}>
                              <TableCell colSpan={5} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      count={this.state.Udata && this.state.Udata.length}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      rowsPerPage={this.state.rowPerPage}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      style={{ fontSize: 16 }}
                    />
                  </Paper>
                </div>
              </div>
            </>
          )}
        </>
      </Fragment>
    );
  }
}

export default withAlert()(ViewDriver);
