import * as React from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  Typography,
} from "@mui/material/";
import Button from "react-bootstrap/Button";
import { AiFillDelete } from "react-icons/ai";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import { API_URL1 } from "../services/url";
import { useAlert } from "react-alert";

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [upd, setUpd] = React.useState(false);
  const [btn_status, setBtn_Status] = React.useState("");

  const alert = useAlert();
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleDelete = async (complain_id) => {
    setUpd(!upd);
    setBtn_Status("clicked");
    try {
      const response = await axios.post(
        `${API_URL1}trucker/complain/deletecomplain?complain_id=${props.complain_id}`
      );
      if (response.data.success) {
        handleClose();
        alert.success(response.data.message, {
          onClose: () => {
            window.location.reload();
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <Paper
        sx={{
          background: "#554D4D",
          borderadRius: "30px",
          width: "100%",
        }}
      >
        <DialogTitle
          align="center"
          sx={{ background: "#554D4D", color: "#fff" }}
        >
          Delete Comment
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem
            autoFocus
            sx={{ backgroundColor: "white", width: "100%", mb: 2 }}
          >
            <Box>
              <Typography
                sx={{ margin: 5 }}
                fontWeight="bold"
                variant="body1"
                color="initial"
              >
                Are you sure you want to delete this comment ?
              </Typography>
              <Box sx={{ display: "flex", marginTop: "20px" }}>
                <Button
                  style={{
                    color: "white",
                    width: "30%",
                    background: "red",
                    cursor: "pointer",
                    width: "30%",
                    borderRadius: "20px",
                    textTransform: "none",
                    marginLeft: "20px",
                  }}
                  variant="text"
                  color="primary"
                  disabled={btn_status=="clicked"}
                  onClick={handleDelete}
                >
                  <b>Yes</b>
                </Button>
                <Button
                  style={{
                    color: "white",
                    width: "30%",
                    background: "#737272",
                    borderRadius: "20px",
                    textTransform: "none",
                    marginLeft: "50px",
                  }}
                  variant="text"
                  color="primary"
                  onClick={handleClose}
                >
                  <b>No</b>
                </Button>
              </Box>
            </Box>
          </ListItem>
        </List>
      </Paper>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function DeleteTruck(props) {
  const { complain_id } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(complain_id);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <AiFillDelete
        style={{
          fontSize: "17px",
          color: "red",
          cursor: "pointer",
          width: "30%",
          borderRadius: "20px",
          textTransform: "none",
          marginLeft: "20px",
        }}
        sx={{ height: "15px", ml: -2.5 }}
        variant="none"
        onClick={handleClickOpen}
      >
        <DeleteOutlineIcon sx={{ color: "red" }} />
      </AiFillDelete>

      <SimpleDialog
        selectedValue={complain_id}
        complain_id={complain_id}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
