import React, { useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { API_URL1 } from "../services/url";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import "./ForgetPass.css";
import logo from "../images/logo.png";
import { useAlert } from "react-alert";
const ForgetPass = () => {
  const [email, setEmail] = useState("");
  // const history = useHistory()
  const alert = useAlert();
  const handleSubmit = async (e) => {
    localStorage.setItem("Cemail", email);
    e.preventDefault();
    try {
      const res = await axios.post(`${API_URL1}trucker/password/forgetPass`, {
        email: email,
      });
      if (res.data.success) {
        alert.success(res.data.message);
        // history.push("/updatePass", { email });
      } else {
        alert.error(res.data.message, {
          onClose: () => {
            // history.push("/forgetpass", { email });
          },
        });
      }
    } catch (err) {
      console.log(err);
      alert.error(err);
    }
  };

  return (
    <div className="main_body">
      <Navbar style={{ backgroundColor: "black" }}>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={logo}
              style={{ height: "7vh", width: "200px" }}
              className="d-inline-block align-top"
              alt="rockprosusa"
            />
          </Link>
        </Navbar.Brand>
      </Navbar>
      <div className="container w-25 pt-5">
        <h1 className="text-center">Forgot Password</h1>
        <Form onSubmit={handleSubmit} className="pt-5 w-100">
          <Form.Label style={{ fontSize: "20px", paddingBottom: "0.5rem" }}>
            Email address
          </Form.Label>
          <Form.Control
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Enter your Registered Email here"
            required={true}
          />
          <button className="submit-btn" type="submit">
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
};

export default ForgetPass;
