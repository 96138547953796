import React, { Fragment, Component } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import { withAlert } from "react-alert";
class ViewDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Udata: [],
      tokenvalue: localStorage.getItem("TruckertokenKey"),
      days: ["", "", "", "", "", "", "", "", "", ""],
      ways: ["", "", "", "", "", "", "", "", "", ""],
      page: 0,
      rowPerPage: 100,
      pageData: [],
      values: [{ truck_id: "", available: "", available_id: "" }],
      values1: [],
      val: [],
      truckstaus: 0,
      loading: true,
    };
  }

  handleChangePage = (newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowPerPage: parseInt(event.target.value) });
    this.setState({ page: 0 });
  };

  async componentDidMount() {
    let d = "";
    let w = "";
    let week = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    for (var i = 0; i < 9; i++) {
      const t1 = new Date();
      const t2 = new Date();
      const t3 = new Date();
      const t4 = new Date();
      if (
        week[new Date(t1.setDate(t1.getDate() + i)).getDay()] === "Sunday" ||
        week[new Date(t2.setDate(t2.getDate() + i)).getDay()] === "Saturday"
      ) {
      } else {
        d = d + "," + week[new Date(t3.setDate(t3.getDate() + i)).getDay()];
        w = w + "," + new Date(t4.setDate(t4.getDate() + i));
      }
    }
    var a = [];
    var w1 = [];
    w1 = w.split(",");
    a = d.split(",");
    for (let i = 1; i < a.length; i++) {
      this.state.days[i] = a[i];
      this.state.ways[i] = moment(w1[i]).format("ll");
      this.setState({
        days: this.state.days,
        ways: this.state.ways,
      });
    }
  }

  emptyRows = () => {
    return (
      this.state.rowPerPage -
      Math.min(
        this.state.rowPerPage,
        this.state.rows.length - this.state.page * this.state.rowsPerPage
      )
    );
  };
  render() {
    const { page, rowPerPage } = this.state;

    return (
      <Fragment>
        {this.props.loading ? (
          <></>
        ) : (
          <div style={{ marginLeft: "45px" }} className="container col-xl-10 ">
            <div className="row text-center pt-3 ">
              <Paper style={{ width: "115%" }}>
                <TableContainer style={{ height: "65vh" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            left: 0,
                            minWidth: 100,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            left: 100,
                            minWidth: 150,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        ></TableCell>

                        <TableCell
                          style={{
                            left: 250,

                            minWidth: 100,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            left: 350,

                            minWidth: 100,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        >
                          Trucks Available
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        ></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            left: 0,

                            minWidth: 100,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        >
                          <i class="fa fa-sort" aria-hidden="true" />
                          Truck
                        </TableCell>
                        <TableCell
                          style={{
                            left: 100,
                            minWidth: 150,

                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        >
                          Truck/Trailer Type
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        >
                          {this.state.days[1]
                            ? this.state.days[1] + " " + this.state.ways[1]
                            : ""}
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        >
                          {this.state.days[2]
                            ? this.state.days[2] + " " + this.state.ways[2]
                            : ""}
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        >
                          {this.state.days[3]
                            ? this.state.days[3] + " " + this.state.ways[3]
                            : ""}
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        >
                          {this.state.days[4]
                            ? this.state.days[4] + " " + this.state.ways[4]
                            : ""}
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        >
                          {this.state.days[5]
                            ? this.state.days[5] + " " + this.state.ways[5]
                            : ""}
                        </TableCell>
                        <TableCell
                          style={{
                            minWidth: 180,
                            backgroundColor: "#000000",
                            color: "white",
                          }}
                        >
                          {this.state.days[6]
                            ? this.state.days[6] + " " + this.state.ways[6]
                            : ""}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ backgroundColor: "#E4E4E4" }}>
                      {this.props.Udata &&
                        this.props.Udata.slice(
                          page * rowPerPage,
                          page * rowPerPage + rowPerPage
                        ).map((item, index) => (
                          <TableRow key={index} tabIndex={-1}>
                            <TableCell
                              align="left"
                              style={{
                                left: 0,
                                backgroundColor: "#E4E4E4",

                                minWidth: 100,
                              }}
                            >
                              {item.registration_number !== null
                                ? item.registration_number
                                : "N/A"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                left: 100,
                                minWidth: 150,
                                backgroundColor: "#E4E4E4",
                              }}
                            >
                              {item.truck_type !== null
                                ? item.truck_type
                                : "N/A"}
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.data[0]
                                      ? item.data[0].available === 0
                                      : ""
                                  }
                                />
                              </div>
                            </TableCell>

                            <TableCell style={{ minWidth: 180 }}>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.data[1]
                                      ? item.data[1].available === 0
                                      : ""
                                  }
                                />
                              </div>
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.data[2]
                                      ? item.data[2].available === 0
                                      : ""
                                  }
                                />
                              </div>
                            </TableCell>
                            <TableCell style={{ minWidth: 180 }}>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.data[3]
                                      ? item.data[3].available === 0
                                      : ""
                                  }
                                />
                              </div>
                            </TableCell>

                            <TableCell style={{ minWidth: 180 }}>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.data[4]
                                      ? item.data[4].available === 0
                                      : ""
                                  }
                                />
                              </div>
                            </TableCell>

                            <TableCell style={{ minWidth: 180 }}>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={
                                    item.data[5]
                                      ? item.data[5].available === 0
                                      : ""
                                  }
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      {this.emptyRows > 0 && (
                        <TableRow style={{ height: "59" * this.emptyRows }}>
                          <TableCell colSpan={5} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={this.state.Udata && this.state.Udata.length}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  rowsPerPage={this.state.rowPerPage}
                  rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  style={{ fontSize: 16 }}
                />
              </Paper>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withAlert()(ViewDriver);
