import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL1 } from "../services/url";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/logo.png";
import "./Signup.css";
import "react-phone-number-input/style.css";
import MuiPhoneNumber from "material-ui-phone-number";
import { useAlert } from "react-alert";
const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [address, setAddress] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const alert = useAlert();
  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      user_name: `${firstName} ${lastName}`,
      email: email,
      password: password,
      phone_no: phone_no,
      company_name: company_name,
      address: address,
    };
    axios
      .post(`${API_URL1}trucker/signup`, user)
      .then((response) => {
        if (response.data.message !== "false") {
          localStorage.setItem("TruckertokenKey", response.data.token);
          localStorage.setItem("userdata", response.data.user.customer_id);
          localStorage.setItem("adminname", response.data.user.name);
          localStorage.setItem("adminid", response.data.user.truck_company_id);
          localStorage.setItem("email", response.data.user.email);
          localStorage.setItem("phone_no", response.data.user.phone_no);
          window.location = "/";
          return <div />;
        }
        alert.success(response.data.message);
      })
      .catch((error) => {
        alert.show(
          "trucker already present please contact  xyz@gmail.com for further information"
        );
      });
  };

  return (
    <div className="scroll login_top">
      <div>
        <Navbar style={{ backgroundColor: "black" }}>
          <Navbar.Brand>
            <Link to="/">
              <img
                src={logo}
                style={{ height: "40px", width: "200px" }}
                className="d-inline-block align-top"
                alt=""
              />
            </Link>
          </Navbar.Brand>
        </Navbar>
      </div>
      <div className="my-login-page" style={{ backgroundColor: "black" }}>
        <div className="container">
          <div
            className="row justify-content-center h-100 w-100"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-6 pt-5 top">
              <h4
                className="card-title text-light"
                align="center"
                style={{ fontSize: "30px" }}
              >
                Sign Up
              </h4>
              <form method="POST" onSubmit={(e) => onSubmit(e)}>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <input
                      placeholder="Company Name"
                      id="company_name"
                      type="text"
                      className="form-control"
                      name="company_name"
                      value={company_name}
                      onChange={(e) => setCompany_name(e.target.value)}
                      required
                      autoFocus
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="contact"
                    style={{ color: "white", marginLeft: "1rem" }}
                  >
                    Company Contact*
                  </label>
                  <div className="col-lg-6">
                    <input
                      placeholder="First Name"
                      id="fName"
                      type="text"
                      className="form-control"
                      name="fName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      autoFocus
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      placeholder="Last Name"
                      id="lName"
                      type="text"
                      className="form-control"
                      name="lName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      autoFocus
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6">
                    <input
                      placeholder="Address of Business"
                      id="addressofbusiness"
                      type="text"
                      className="form-control"
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                      autoFocus
                    />
                  </div>
                  <div className="col-lg-6">
                    <MuiPhoneNumber
                      style={{ width: "100%", backgroundColor: "white" }}
                      name="phone"
                      data-cy="user-phone"
                      defaultCountry={"us"}
                      value={phone_no}
                      onChange={setPhone_no}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6">
                    <input
                      placeholder="Business Email"
                      id="email"
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      required
                      autoFocus
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      placeholder="Password"
                      id="password"
                      type="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoFocus
                    />
                  </div>
                </div>
                <div className="form-group no-margin">
                  <input
                    type="submit"
                    value="SignUp"
                    className="button button2 btn-block"
                    style={{
                      backgroundColor: " #2A707D",
                      fontSize: "20px",
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
