import React from "react";
import Modal from "react-bootstrap/Modal";
import { withAlert } from "react-alert";
import { FaFileDownload } from "react-icons/fa";
class DownloadQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      Pdfname: "",
      btn_status: "unclick",
      loading: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
    window.location.reload()

    // window.location = "/dilevery_order";
  };

  render() {
    return (
      <React.Fragment>
        <div>
          {this.props.pdf ? (
            <FaFileDownload
              style={{
                color: "green",
                fontSize: "15px",
                marginLeft: "6px",
                cursor: "pointer",
              }}
              onClick={(evt) => this.setState({ show: true })}
            />
          ) : (
            <FaFileDownload
              style={{
                color: "grey",
                fontSize: "15px",
                marginLeft: "6px",
              }}
            />
          )}
        </div>

        <Modal show={this.state.show} onHide={this.handleClose} size="lg"style={{marginTop:'4%'}}>
          {/* <div
            style={{
             width:'auto',
             height:'auto'
            }}
          > */}
            {!this.props.pdf ? (
              <div className="spinner-border text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <img
                style={{
                  // maxWidth:"100%",
                  width:'100%',
                  // // width: "100%",
                  // maxHeight: "60%",
                  height:"auto",
                  objectFit: "cover",
                  border: "2.5px solid black",
                }}
                alt="pdf"
                src={this.props.pdf}
                type="application/pdf"
              />
            )}
          {/* </div> */}
        </Modal>
      </React.Fragment>
    );
  }
}
export default withAlert()(DownloadQuote);
