import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { MdEdit } from "react-icons/md";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import LinearProgress from "@material-ui/core/LinearProgress";
import { API_URL1 } from "../services/url";
import axios from "axios";
import { useState, useEffect } from "react";
import DeleteDriver from "./DeleteDriver";

const ViewDriver = () => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const [Udata, setUdata] = useState([]);
 
  const [record, setRecord] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${API_URL1}trucker/driver/getDrivers?truck_company_id=${Number(
            localStorage.getItem("adminid")
          )}`,
          {
            headers: {
              "x-access-token": localStorage.getItem("TruckertokenKey"),
              "content-type": "application/json",
            },
          }
        );

        if (response.data.trucks) {
          setUdata(response.data.trucks);
          setRecord(true);
        } else {
          setUdata(response.data.trucks);
          setRecord(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Fragment>
      {!record ? <LinearProgress /> : ""}
      <Grid container style={{ marginBottom: "2%", marginTop: "2%" }}>
        <Grid item xs={4} sm={2}>
          <Link to="#">
            <Button
              style={{
                float: "left",
                // marginTop: "3%",
                marginLeft: "2.5rem",
                width: "132px",
                color: "white",
                backgroundColor: "#114C65",
                borderRadius: "25px",
              }}
            >
              Driver
            </Button>
          </Link>
        </Grid>
     
        <Grid item xs={6} sm={3}>
          <Link to="/adddriver">
            <Button
              style={{
                float: "left",
                marginLeft: "2rem",
                width: "132px",
                color: "white",
                backgroundColor: "#000",
                borderRadius: "25px",
              }}
            >
              {" "}
              Add Driver
            </Button>
          </Link>
        </Grid>
      </Grid>
      {/*............................................................................................................................................. TRUCK TABLE */}
      {!record && Udata.length === 0 ? (
        <div style={{ marginLeft: "4%" }}> Loading.........</div>
      ) : Udata.length === 0 && record ? (
        <div style={{ marginLeft: "4%" }}>No Record Available</div>
      ) : (
        <Paper style={{ width: "110%", marginLeft: "3%" }}>
          <TableContainer
            sx={{
              background: "#F3F3F3",
              boxShadow: "5px 5px 6px #434344",
              borderRadius: "10px",
            }}
            style={{ height: "60vh" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead style={{ backgroundColor: "#000000" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Driver's Name
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                    align="center"
                  >
                    Default Truck
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                    align="center"
                  >
                    Truck/Traller type
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                    align="center"
                  >
                    Contact Number
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody style={{ backgroundColor: "#E4E4E4" }}>
                {Udata &&
                  Udata.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((item, key) => (
                    <TableRow
                      key={key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {item.registration_number}
                      </TableCell>
                      <TableCell align="center">{item.type}</TableCell>
                      <TableCell align="center">{item.phone_no}</TableCell>

                      <TableCell align="center">
                        <Link
                          to={{
                            pathname: `/editDriver/${item.truck_company_user_id}/${item.truck_id}`,
                          }}
                        >
                          <MdEdit
                            style={{ color: "#0075ff", fontSize: "17px" }}
                            sx={{ ml: -2.5 }}
                            variant="none"
                          >
                            <ModeEditOutlineOutlinedIcon
                              sx={{ color: "#0075FF" }}
                            />
                          </MdEdit>
                        </Link>
                        <DeleteDriver data={item.truck_company_user_id} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={Udata && Udata.length}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={{ fontSize: 16 }}
          />
        </Paper>
      )}
    </Fragment>
  );
};

export default ViewDriver;
