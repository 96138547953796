import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Card from "@material-ui/core/Card";
import axios from "axios";
import { API_URL1 } from "../services/url";
import CurrentLocation from "./CurrentLocation";
import Button from "react-bootstrap/Button";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";

const ProsMap = (props) => {
  const [center, setCenter] = useState({ lat: 32.74580332356024, lng: -111.6859092361447 });
  const [zoom, setZoom] = useState(10);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [qty, setQty] = useState("");
  const [truck, setTruck] = useState("");
  const [driver, setDriver] = useState("");
  const [customer_name, setCustomer_name] = useState("");
  const [customer_site_name, setCustomer_site_name] = useState("");
  const [src, setSrc] = useState({
    lat: "",
    lng: "",
  });
  const [destination, setDestination] = useState({
    lat: "",
    lng: "",
  });
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(
        `${API_URL1}trucker/dispatch/dispatchdelivered/${localStorage.getItem(
          "adminid"
        )}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("TruckertokenKey"),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (response.data.truckes) {
        for (let i = 0; i < response.data.truckes.length; i++) {
          if (
            response.data.truckes[i].sub_job_truck_id ===
            Number(props.match.params.sub_job_truck_id)
          ) {
            setColor(response.data.truckes[i].color_name);
            setCustomer_name(response.data.truckes[i].customer_name);
            setSize(response.data.truckes[i].size);
            setQty(response.data.truckes[i].processing_quantity);
            setTruck(response.data.truckes[i].registration_number);

            setDriver(response.data.truckes[i].driver_name);
            setCustomer_site_name(response.data.truckes[i].customer_site_name);
            setSrc({
              lat: JSON.parse(response.data.truckes[i].quarry_geofence).lat,
              lng: JSON.parse(response.data.truckes[i].quarry_geofence).lng,
            });
            setDestination({
              lat: parseFloat(JSON.parse(response.data.truckes[i].customer_geofence).lat),
              lng:  parseFloat(JSON.parse(response.data.truckes[i].customer_geofence).lng),
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleApiLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();
    directionsRenderer.setMap(map);

    const onChangeHandler = function () {
      calculateAndDisplayRoute(directionsService, directionsRenderer);
    };
    onChangeHandler();

    function calculateAndDisplayRoute(directionsService, directionsRenderer) {
      directionsService.route(
        {
          origin: src,
          destination: destination,

          travelMode: maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            directionsRenderer.setDirections(response);
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    }
  };
  return (
    <TableContainer
      component={Paper}
      style={{
        marginBottom: "10%",
        marginTop: "1.5%",
        marginLeft: "2%",
        boxShadow: "5px 5px 6px #434344",
        backgroundColor: "rgba(28, 139, 133, 0.15)",
      }}
      sx={{
        background: "rgba(28, 139, 133, 0.15)",
        width: "95%",
        height: "84vh",
        borderRadius: "10px",
      }}
      align="center"
    >
      <div
        className="container justify-space-around  row"
        style={{ width: "100%" }}
      >
        <>
          <div className="col-3">
            <Link to="/dilevery_order">
              <Button
                size="small"
                style={{ backgroundColor: "#2A707D" }}
                className="mr-3 mt-2 mb-2"
              >
                Back
              </Button>
            </Link>
            <Card
              style={{
                backgroundColor: "#f0f0f0",
                boxShadow: "5px 5px 8px #888888",
                marginBottom: "3px",
              }}
            >
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  Customer Name :{customer_name}
                </h5>
                <h6 className="card-subtitle" style={{ fontWeight: "bold" }}>
                  Order Detail :
                </h6>
                <p>
                  <strong style={{ textAlign: "left" }}>
                    Customer Site :{customer_site_name}
                  </strong>
                  <br />
                  <strong style={{ textAlign: "left" }}>Size :{size}</strong>
                  <br />
                  <strong style={{ textAlign: "left" }}>
                    Color :{color}
                  </strong>{" "}
                  <br />
                  <strong>
                    Quantity :{qty ? parseFloat(qty).toFixed(2) : "N/A"}
                  </strong>
                  <br />
                  <strong>Truck # :{truck}</strong> <br />
                  <strong>Driver Name :{driver}</strong> <br />
                </p>
              </div>
            </Card>
          </div>
          <div style={{ height: "80vh", width: "70%", marginLeft: "30px" }}>
            {!src.lat ? (
              <>
                <CurrentLocation hidden /> <p>Map Loading...</p>
              </>
            ) : (
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
                defaultCenter={center}
                defaultZoom={zoom}
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
                yesIWantToUseGoogleMapApiInternals
              ></GoogleMapReact>
            )}
          </div>
        </>
      </div>
    </TableContainer>
  );
};
export default ProsMap;
