import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./DispatchOrder.css";
import axios from "axios";
import { API_URL1 } from "../services/url";
import moment from "moment";
import { withAlert } from "react-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LinearProgress } from "@material-ui/core";
import Button from "react-bootstrap/Button";

class rescheduleOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current_date: moment(Date.now()).format().substring(0, 10),
      deliveryDate: "",
      availTrucks: [],
      trucks: [],
      truckers: [],
      truck_or_trucker: "",
      truck_number: "",
      recentOrder:[],
      record:false,
      arr: [],
      Udata: [],
      truck_capacity:"",
      truck_type:"",
      select_truck_type:"",
      truck_id:"",
      quantity:"",
      no_of_trips:"",
      capacity_error:"no",
      // total_quantity_error:false
    };
  }


  componentDidMount = async () => {
    try {
      const response = await axios.get(
        `${API_URL1}trucker/dispatch/rescheduleform/${localStorage.getItem("adminid")}/${this.props.match.params.id}
        `,
        {
          headers: {
            "x-access-token": localStorage.getItem("TruckertokenKey"),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      console.log("consoling", response.data.truckes)

      if (response.data.truckes) {
        this.setState({
          recentOrder: response.data.truckes[0],
          truck_quantity:response.data.truckes[0].processing_quantity,
          nonFilteredData: response.data.truckes,
          record: true,
          truck_or_trucker: "truck",
          sub_job_truck_id:response.data.truckes[0].sub_job_truck_id,
          truck_type: response.data.truckes[0].truck_type
        });
        console.log("trucks:    ", this.recentOrder)
        try {
            const response = await axios.get(
              `${API_URL1}trucker/driver/getDrivers?truck_company_id=${Number(
                localStorage.getItem("adminid")
              )}`,
              {
                headers: {
                  "x-access-token": localStorage.getItem("TruckertokenKey"),
                  "content-type": "application/json",
                },
              }
            );
            this.setState({
              Udata: response.data.trucks,
            });
            console.log("udata", this.state.Udata)
          } catch (error) {
            console.log(error);
          }
      } else {
        this.setState({ recentOrder: [], record: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleTruckById = async (evt, index) => {
    evt.preventDefault();
    console.log("index", evt.target.value);
    if (evt.target.value) {
      let new_arr = [];
      let no_of_trips,truck_quantity=this.state.truck_quantity;
      new_arr = evt.target.value.split(",");

      if (Number(new_arr[1])<truck_quantity) {
        var trips = truck_quantity% new_arr[1] !== 0 ? parseInt(truck_quantity/ new_arr[1]) + 1 : truck_quantity/ new_arr[1]
        console.log("Tipsss", trips)
        no_of_trips = trips;
        this.setState({ capacity_error: "yes",no_of_trips:no_of_trips });

      } else {
        no_of_trips = 1
        this.setState({ capacity_error: "no",no_of_trips:no_of_trips  });
      }
      this.setState({ truck_id: new_arr[0] ,
      truck_capacity:new_arr[1],
      select_truck_type:new_arr[2]});
   
      if (new_arr[0] !== "") {
        try {
          const response = await axios.get(
            `${API_URL1}dispatcher/driverName?truck_id=${new_arr[0]}`
          );

          if (response.data.Name[0].name) {
            this.setState({ driver_name:response.data.Name[0].name,
            driver_id: response.data.Name[0].truck_company_user_id});
          }
        } catch (error) {
          this.setState({ driver_name: "" });
        }
      }
    };

  }
  handleSubmit = async (evt) => {
    evt.preventDefault();
    let data = [];

      var mode = this.state.truck_quantity % this.state.truck_capacity;
      if (mode !== 0) {
        for (let k = 0; k < parseInt(this.state.no_of_trips - 1); k++) {
          console.log("jjjjjjjj=======", k)

          data.push({
            truck_id: Number(this.state.truck_id),
            driver_id: Number(this.state.driver_id),
            assigned_quantity: Number(this.state.truck_capacity),
            no_of_trips: 1
          })
        }
        data.push({
          truck_id: Number(this.state.truck_id),
          driver_id: Number(this.state.driver_id),
          assigned_quantity: mode,
          no_of_trips: 1
        })
      }
      else {
        for (let k = 0; k < parseInt(this.state.no_of_trips); k++) {
          console.log("inside else", k)

          data.push({
            truck_id: Number(this.state.truck_id),
            driver_id: Number(this.state.driver_id),
            assigned_quantity: Number(this.state.truck_capacity),
            no_of_trips: 1
          })
        }
      }
    // for (let j = 0; j < arr_temp_data.length; j++) {
    //   total_quantity += arr_temp_data[j].quantity;
    // }
    // console.log("temp_truck_Data", data ,total_quantity)

    // if (total_quantity > this.state.shipped_qty) {
    //   this.setState({ total_quantity_error: true });
    // } else {
    //   this.setState({ total_quantity_error: false });
    // }

    const alert = this.props.alert;
    if (
      this.state.truck_or_trucker === "truck"
    ) {
      try {
        this.setState({ btn_clicked: "clicked" });
        const formdata = {
          sub_job_truck_id:this.state.sub_job_truck_id,
          trucks: data,
          company_name:localStorage.getItem("company_name"),
          trucker_email:localStorage.getItem("Temail")
        };

        const response = await axios.post(
          `${API_URL1}trucker/dispatch/rescheduleOrder`,
          formdata,
          {
            headers: {
              "x-access-token": localStorage.getItem("TruckertokenKey"),
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        if (response.data.success) {
          alert.success(response.data.message, {
            onClose: () => {
              window.location = "/dispatcher";
            },
          });
        } else {
          alert.error("Order in not assign", {
            onClose: () => {
              window.location = "/dispatcher";
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleChange = (evt) => {
    this.setState({ trucker_company_id: evt.target.value });
  };
  handleQuarryIdChange(e) {
    this.setState({ quarry_id: e.target.value });
  }
  handleQuantity = async (e, id) => {
    try {
      const response = await axios.get(
        `${API_URL1}aggregate/querryList?size_id=${id}`
      );

      this.setState({
        quarry: response.data.result,
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleDate = async (newDate) => {
    this.setState({ dataPU: newDate });

    const formdata1 = {
      truck_company_id: localStorage.getItem("adminid"),
      dates: moment(newDate).format("yyyy-MM-DD"),
    };
    try {
      const response2 = await axios.post(
        `${API_URL1}trucker/dispatch/availTruck1`,
        formdata1
      );

      let t_type = this.state.truck_type.split(",");

      let availTrucks = [];
      let flag = 0;

      for (let i = 0; i < response2.data.Trucks.length; i++) {
        for (let j = 0; j < t_type.length; j++) {
          if (t_type[j] === response2.data.Trucks[i].type) {
            flag = 1;
            availTrucks.push(response2.data.Trucks[i]);
          }
        }
      }
      if (flag === 1) {
        this.setState({
          availTrucks: availTrucks,
        });
      }

      if (this.state.truck_type.includes("Any (No restrictions)")) {
        this.setState({
          availTrucks: response2.data.Trucks,
        });
      }
      var flag2 = 0;
      for (let i = 0; i < t_type.length; i++) {
        if (
          t_type[i] === "Any (No restrictions)" ||
          t_type[i] === "Flatbed" ||
          t_type[i] === "Live Bottom" ||
          t_type[i] === "10 Wheel" ||
          t_type[i] === "Rock Tub" ||
          t_type[i] === "Super 16" ||
          t_type[i] === "Super 18" ||
          t_type[i] === "End Dump" ||
          t_type[i] === "Belly Dump" ||
          t_type[i] === "Transfer"
        ) {
          flag2 = 1;
        }
      }
      if (flag2 === 0) {
        this.setState({
          availTrucks: response2.data.Trucks,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleDriver = async ( evt) => {
    let res = [];
    res = evt.target.value.split(",");
    this.setState({ 
        driver_name:res[0],
        driver_id:res[3] 
    });
  };
  render() {
    const { recentOrder} = this.state;
    var oneYearFromNow = new Date();
    var after_oneyear = oneYearFromNow.setFullYear(
      oneYearFromNow.getFullYear() + 1
    );

    return (
      <Fragment>
        {!this.state.record ? <LinearProgress /> : ""}
        <Link to={`/`}>
          <Button
            style={{
              marginTop: "2%",
            
              marginBottom: "10px",
              width: "100px",
              color: "white",
              backgroundColor: "#114C65",
              borderRadius: "10px",
            }}
          >
            Back
          </Button>
        </Link>

        <div
          className="mb-5 main__body d-flex"
          style={{
            overflow: "scroll",
            height: "75vh",
            marginLeft: "95px",
            width: "70vw",
            padding: "20px",
          }}
        >
          <div className="row container main__form col-lg-12">
            <form  onSubmit={(evt) => this.handleSubmit(evt)}>
              <div className="row form-group div_small">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  Order:
                </label>
                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  required
                  placeholder="Order"
                  value={recentOrder.po_number}
                />
              </div>
              <div></div>
              <div className="row form-group div_small">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  PO:
                </label>
                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  required
                  placeholder="PO"
                  value={!recentOrder.PO_No ? 0 : recentOrder.PO_No}
                />
              </div>
              <div className="row form-group div_small">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  Address:
                </label>
                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  required
                  placeholder="PO"
                  value={
                    recentOrder.address_line_1 +
                    " " +
                    recentOrder.city +
                    " " +
                    recentOrder.pincode
                  }
                />
              </div>
              <div className="row form-group div_small ">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  Order Date:
                </label>
                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  required
                  placeholder="Order Date"
                  value={
                    recentOrder.currentDate
                      ? moment( recentOrder.currentDate).format("ll")
                      : ""
                  }
                />
              </div>
              <div className="row form-group div_small ">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  Color:
                </label>
                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  required
                  placeholder="Color"
                  value={recentOrder.color_name}
                />
              </div>
              <div className="row form-group div_small ">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  Size:
                </label>
                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  required
                  placeholder="Size"
                  value={recentOrder.size}
                />
              </div>
              <div className="row form-group div_small ">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  P/U Quarry
                </label>

                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  required
                  placeholder="P/U Quarry"
                  value={recentOrder.quarry_site_name}
                />
              </div>
              <div className="row form-group div_small ">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  T/T Requirement
                </label>
                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  required
                  placeholder="T/T Requirement"
                  value={recentOrder.truck_type}
                />
              </div>
              <div className="row form-group div_small ">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  Amount to be Dispatched:
                </label>
                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  placeholder="Original Qty"
                  value={
                    recentOrder.processing_quantity
                      ? parseFloat(recentOrder.processing_quantity).toFixed(2)
                      : "N/A"
                  }
                />
              </div>
              <div className="row form-group div_small ">
                <label className="col-lg-4 pt-2" htmlFor="OrderID">
                  Desired Delivery Date
                </label>
                <input
                  className="form-control col-lg-5"
                  disabled
                  aria-describedby="textHelp"
                  required
                  placeholder="Shipped Qty"
                  value={
                    recentOrder.to_date
                      ? moment(recentOrder.to_date).format("ll")
                      : "N/A"
                  }
                />
              </div>
              <div className="row form-group div_small ">
                <label className="col-lg-3" htmlFor="OrderID">
                  Date to P/U
                </label>
                <div
                  style={{ marginLeft: "57px", width: "90%" }}
                  className="col-lg-6"
                >
                  <DatePicker
                    minDate={new Date()}
                    maxDate={after_oneyear}
                    placeholderText="mm-dd-yyyy"
                    className="form-control"
                    showPopperArrow={false}
                    selected={this.state.dataPU}
                    onChange={(newDate) => this.handleDate(newDate)}
                    dateFromat="YYYY-MM-dd"
                    required
                  />
                </div>
              </div>

              {this.state.truck_or_trucker === "truck" ? (
                <div className="col-lg-9 div_small d-flex">
                  <label className="col-lg-2 mr-1">Truck</label>
                  <label className="col-lg-2 mr-1">Driver</label>
                  <label className="col-lg-3 ml-5 ">Truck/Trailer type</label>
                  <label className="col-lg-2 ml-4">Capacity</label>

                  <label className="col-lg-2 ">Quantity</label>
                  <label className="col-lg-2 ">No of trips</label>

                </div>
              ) : (
                ""
              )}

             
                
                    <>
                      <div className="col-lg-9 div_small d-flex">
                        {/* <label className="mr-1">{key + 1}</label> */}
                        <select
                          required
                          style={{ height: "35px" }}
                          placeholder="Select Query"
                          className="form-control col-lg-2 mr-1 "
                          onChange={(e) => {
                            this.handleTruckById(e);
                          }}
                        >
                          <option value="" required>
                            Select
                          </option>
                          {this.state.availTrucks &&
                            this.state.availTrucks.map((availTrucks) => {
                              return availTrucks.registration_number !==
                                null ? (
                                <option
                                  value={[
                                    availTrucks.truck_id,
                                    availTrucks.capacity,
                                    availTrucks.type,
                                  ]}
                                >
                                  {availTrucks.registration_number}
                                </option>
                              ) : (
                                ""
                              );
                            })}
                        </select>
                        <input
                          style={{ marginRight: "-5px", borderRight: "none" }}
                          className="col-lg-2 form-control impinput "
                          disabled
                          aria-describedby="textHelp"
                          required
                          placeholder="Driver"
                         value={this.state.driver_name}
                        />
                        <select

                          style={{ height: "35px" }}
                          className="form-control  mr-1"
                          onChange={(e) => {
                            this.handleDriver( e);
                          }}
                        >
                          {/* <option value={this.state.arr[key].driver}></option> */}
                          {this.state.Udata &&
                            this.state.Udata.map((item, key) => {
                              return (
                                <option
                                  key={key}
                                  value={[
                                    item.name,
                                    item.truck_id,
                                    item.phone_no,
                                    item.truck_company_user_id,
                                    item.type,
                                  ]}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                        <input
                          className="col-lg-3 form-control mr-1"
                          disabled
                          aria-describedby="textHelp"
                          required
                          placeholder="Truck/Trailer Type"
                          value={this.state.select_truck_type}
                        />
                        <div className="col-lg-2">
                          <input
                            style={{ width: "85px" }}
                            className=" form-control mr-1 "
                            aria-describedby="textHelp"
                            required
                            disabled
                            placeholder="Capacity"
                            value={this.state.truck_capacity}
                          />
                         
                        </div>
                        <input
                          className="col-lg-2 form-control mr-1 "
                          aria-describedby="textHelp"
                          required
                          disabled
                          placeholder="Quantity"
                          value={recentOrder.processing_quantity}
                        />
                        <input
                          className="col-lg-2 form-control mr-1 "
                          aria-describedby="textHelp"
                          required
                          placeholder="No of trips"
                          onChange={(evt) => {
                            this.handleChangeTrips( evt);
                          }}
                          value={this.state.no_of_trips}
                        />
                      </div>
                    </>
                  
               
              {/* <div className="col-lg-8 div_small d-flex">
                {this.state.total_quantity_error ? (
                  <p style={{ color: "red" }}>
                    must be less then shipped quantity
                  </p>
                ) : (
                  ""
                )}
              </div> */}
              <div className="col-lg-8 div_small d-flex">
                <Button
                  type="submit"
                  style={{
                    float: "left",
                    marginTop: "3%",
                    marginLeft: "280px",
                    marginBottom: "15px",
                    width: "165px",
                    color: "white",
                    backgroundColor: "#114C65",
                    borderRadius: "25px",
                  }}
                  disabled={this.state.btn_clicked === "clicked"}
                >
                  {" "}
                  Schedule Dispatch
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(rescheduleOrder);
