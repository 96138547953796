import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL1 } from "../services/url";
import { useAlert } from "react-alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  root: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },
});
const EditTruck = (props) => {
  const location = useLocation();
  console.log(location, "this is Location")
  const { edittruckdata } = location.state;
  const alert = useAlert("");
  const [show, setshow] = useState(false);
  const [vehicleNo, setVeichleNo] = useState("");
  const [truck_type_id, setTruckTypeId] = useState("");
  const [truckType, setTruckType] = useState([]);
  const [capacity, setcapacity] = useState("");
  const [brandModel, setbrandModel] = useState("");
  const [truck, setTruck] = useState("");
  const [color, setcolor] = useState("");
  const [btn_clicked, setbtn_clicked] = useState("unclick");
  const [truck_number, setTruck_Number] = useState("");


  const classes = useStyles();
  useEffect(() => {
    showModel();
  }, []);
  const showModel = async (evt) => {
    const truck_id = edittruckdata;
    try {
      const response1 = await axios.get(`${API_URL1}trucker/truck/truckType`);
      setTruckType(response1.data.Truck_type);
    } catch (error) {
      console.log(error);
    }
    try {
      const response = await axios.get(
        `${API_URL1}trucker/truck/trucks/${truck_id}`,

        {
          headers: {
            "x-access-token": `${localStorage.getItem("TruckertokenKey")}`,
            "content-type": "application/json",
          },
        }
      );

      setTruck(response.data.trucks[0].registration_number);

      setcolor(response.data.trucks[0].color);
      setVeichleNo(response.data.trucks[0].vehicle_no);
      setbrandModel(response.data.trucks[0].brand_model);
      setcapacity(response.data.trucks[0].capacity);
      setshow(true);
      setTruckTypeId(response.data.trucks[0].truck_type_id);
      setTruck_Number(response.data.trucks[0].truck_number);
    } catch (error) {
      console.log(error);
    }
  };
  const hideModal = () => {
    setshow(false);
  };
  const handleAdd = async (event) => {
    event.preventDefault();
    const truck_id = event.target.id;
    setbtn_clicked("clicked");

    const user = {
      truck_company_id: Number(localStorage.getItem("adminid")),
      brand_model: brandModel,
      vehicle_no: vehicleNo,
      capacity: capacity,
      truck_type_id: truck_type_id,
      registration_number: truck,
      color: color,
      truck_number: truck_number,
    };

    try {
      const response1 = await axios.put(
        `${API_URL1}trucker/truck/truckEdit/${edittruckdata}`,
        user,
        {
          headers: {
            "x-access-token": `${localStorage.getItem("TruckertokenKey")}`,
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (response1.data.success) {
        hideModal();
        alert.success(response1.data.message, {
          onClose: () => {
            window.location = "/viewtruck";
          },
        });
      } else {
        hideModal();
        alert.error(response1.data.message, {
          onClose: () => {
            window.location = "/viewtruck";
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <TableContainer
      component={Paper}
      style={{
        marginTop: "2%",
        marginRight: "2%",
        marginLeft: "20%",
        boxShadow: "5px 5px 6px #434344",
      }}
      sx={{
        background: "#F3F3F3",
        width: "80%",
        height: "84vh",
        borderRadius: "10px",
      }}
      align="center"
    >
      <form onSubmit={handleAdd}>
        <Typography
          fontWeight="bold"
          variant="h6"
          color="initial"
          fontSize="22px"
          paddingBottom="2%"
          paddingTop="2%"
        >
          Edit Truck
        </Typography>

        <Grid
          container
          spacing={3}
          sx={{
            padding: "0 5% 0 5%",
          }}
        >
          <Grid item xs={8} sm={4}>
            {" "}
            <Typography
              sx={{ mb: 1 }}
              align="left"
              fontSize="18px"
              fontWeight="bold"
            >
              Truck:{" "}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
              }}
              size="small"
              type="string"
              onChange={(e) => setTruck(e.target.value)}
              value={truck}
              autoComplete="given-name"
              required
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            <Typography
              sx={{ mb: 1 }}
              fontSize="18px"
              fontWeight="bold"
              align="left"
            >
              Brand Name:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            {" "}
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
              }}
              type="string"
              value={brandModel}
              onChange={(e) => setbrandModel(e.target.value)}
              required
              fullWidth
              size="small"
              className={classes.root}
              border="none"
            />
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            <Typography
              sx={{ mb: 1 }}
              fontSize="18px"
              fontWeight="bold"
              align="left"
            >
              Model Number:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            {" "}
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
              }}
              type="string"
              value={vehicleNo}
              onChange={(e) => setVeichleNo(e.target.value)}
              required
              fullWidth
              size="small"
              className={classes.root}
              border="none"
            />
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            <Typography
              sx={{ mb: 1 }}
              fontSize="18px"
              fontWeight="bold"
              align="left"
            >
              Truck Number:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            {" "}
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
              }}
              type="string"
              value={truck_number}
              onChange={(e) => setTruck_Number(e.target.value)}
              required
              fullWidth
              size="small"
              className={classes.root}
              border="none"
            />
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            <Typography
              sx={{ mb: 1 }}
              align="left"
              fontSize="18px"
              fontWeight="bold"
            >
              Color:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <TextField
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
                border: "none",
              }}
              size="small"
              className={classes.root}
              border="none"
              required
              type="string"
              value={color}
              onChange={(e) => setcolor(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={4}>
            <Typography
              sx={{ mb: 1 }}
              align="left"
              fontSize="18px"
              fontWeight="bold"
            >
              Truck/Trail type:{" "}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                height: "41px",
                width: "100%!important",
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
                border: "none",
              }}
              value={truck_type_id}
              onChange={(e) => setTruckTypeId(e.target.value)}
            >
              {truckType.map((truck, key) => {
                if (truck_type_id == truck.truck_type_id) {
                  return (
                    <MenuItem key={key} value={truck.truck_type_id}>
                      {truck.type}
                    </MenuItem>
                  );
                } else
                  return (
                    <MenuItem key={key} value={truck.truck_type_id}>
                      {" "}
                      {truck.type}
                    </MenuItem>
                  );
              })}
            </Select>
          </Grid>
          <Grid item xs={8} sm={4}></Grid>{" "}
          <Grid item xs={8} sm={4}>
            <Typography
              sx={{ mb: 1 }}
              align="left"
              fontSize="18px"
              fontWeight="bold"
            >
              Max Tons Hauled:
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <TextField
              style={{
                width: "212px!important",
              }}
              sx={{
                background: "#FFFFFF",
                boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px!important",
                border: "none",
              }}
              size="small"
              className={classes.root}
              border="none"
              required
              type="string"
              value={capacity}
              onChange={(e) => setcapacity(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={8} sm={4}></Grid>
          <Grid item xs={8} sm={3}></Grid>{" "}
          <Grid item xs={8} sm={6}>
            <Button
              variant="contained"
              width="180px"
              id={edittruckdata}
              type="submit"
              style={{
                marginTop: "3%",
                paddingLeft: "50px",
                paddingRight: "50px",
                backgroundColor: "#000",
                borderRadius: "20px",
              }}
              disabled={btn_clicked==="clicked"}
            >
              <Typography color="white">Update Truck</Typography>
            </Button>
          </Grid>{" "}
          <Grid item xs={8} sm={3}></Grid>
        </Grid>
      </form>
    </TableContainer>
  );
};

export default EditTruck;
